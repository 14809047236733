<template>
  <div>
    <!-- select 2 demo -->

    <div class="" style="display: flex">
      <b-modal
        id="modal-upload"
        cancel-variant="outline-secondary"
        hide-footer
        centered
        no-close-on-backdrop
        title="Upload"
      >
        <b-form>
          <b-form-group
            label="Date Column"
            invalid-feedback="Date Column is required."
            ref="dt_col"
          >
            <b-form-input v-model="uploadSettings.dateColumn" />
          </b-form-group>
        </b-form>
        <b-row class="mt-2">
          <b-col style="display: flex; justify-content: end">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="primary"
              :disabled="savingSettings"
            >
              <b-spinner v-if="savingSettings" small type="grow" />
              <span v-else class="align-middle">Save</span>
            </b-button>
          </b-col>
        </b-row>
      </b-modal>

      <b-modal
        id="modal-receipt"
        cancel-variant="outline-secondary"
        hide-footer
        centered
        no-close-on-backdrop
        title="Deleted Receipts"
        size="lg"
        body-class="pl-0 pr-0"
      >
        <b-table
          class=""
          sticky-header
          :items="receiptData"
          :fields="receiptFields"
          responsive
          hover
          :busy="recLoading"
          show-empty
          small
        >
          <template #table-busy>
            <div
              class="d-flex justify-content-center mb-3"
              style="margin-top: 50px"
            >
              <b-spinner
                style="width: 3rem; height: 3rem"
                type="grow"
                variant="primary"
                label="Spinning"
              ></b-spinner>
            </div>
          </template>
          <template #head(actions)="data">
            <div class="text-center">
              <span>{{ data.label }}</span>
            </div>
          </template>

          <template #cell(gr)="data">
            {{ data.item.stdd.grNo }}
          </template>
          <template #cell(name)="data">
            {{ data.item.stdd.name }}
          </template>
          <template #cell(class)="data">
            {{ data.item.cls.name }} - {{ data.item.sec.section }}
          </template>
          <template #cell(feeType)="data">
            {{ data.item.feesType.feeType }}
          </template>
          <template #cell(monthYear)="data">
            <b-badge variant="light-primary">
              {{ data.item.fees.monthYear }}
            </b-badge>
            <br />
            <b-badge
              variant="light-danger"
              style="margin-top: 4px"
              v-if="data.item.fees.status == 'hold'"
            >
              On Hold
            </b-badge>
          </template>
          <template #cell(lateFee)="data">
            <b-badge variant="light-primary">
              {{ data.item.fees.lateFee }}
            </b-badge>
          </template>
          <template #cell(timelyDiscount)="data">
            <b-badge variant="light-primary">
              {{ data.item.fees.timelyDiscount }}
            </b-badge>
          </template>
          <template #cell(balance)="data">
            <b-badge variant="light-primary">
              {{ data.item.fees.balance }}
            </b-badge>
          </template>
        </b-table>
      </b-modal>

      <b-modal
        id="modal-slip"
        cancel-variant="outline-secondary"
        hide-footer
        centered
        no-close-on-backdrop
        title="Print Slips"
      >
        <b-row class="">
          <b-col md="6">
            <b-form-group
              label="From"
              invalid-feedback="From is required."
              ref="slip_from"
            >
              <b-form-input
                type="number"
                v-model.trim="slipObj.from"
                placeholder="Enter Slip No."
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Till"
              invalid-feedback="Till is required."
              ref="slip_till"
            >
              <b-form-input
                type="number"
                v-model.trim="slipObj.till"
                placeholder="Enter Slip No."
              />
            </b-form-group>
          </b-col>

          <b-col class="text-right">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="primary"
              :disabled="printingSlip"
              @click="printBulk()"
            >
              <b-spinner v-if="printingSlip" small type="grow" />
              <span v-else class="align-middle">Print</span>
            </b-button>
          </b-col>
        </b-row>
      </b-modal>

      <b-modal
        id="modal-exempt"
        cancel-variant="outline-secondary"
        hide-footer
        centered
        no-close-on-backdrop
        title="Exempt Fee"
      >
        <b-row class="">
          <b-col md="12">
            <b-form-group
              label="Reason"
              invalid-feedback="Reason is required."
              ref="ex_reason"
            >
              <b-form-textarea
                placeholder="Enter reason"
                rows="3"
                v-model.trim="exObj.reason"
              />
            </b-form-group>
          </b-col>

          <b-col class="text-right">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="primary"
              :disabled="exempting"
              @click="ExemptWithNote()"
            >
              <b-spinner v-if="exempting" small type="grow" />
              <span v-else class="align-middle">Exempt</span>
            </b-button>
          </b-col>
        </b-row>
      </b-modal>

      <b-sidebar
        id="sidebar-right"
        ref="mySidebar"
        bg-variant="white"
        v-model="showUpload"
        sidebar-class="sidebar-lg"
        right
        no-header
        backdrop
        shadow
      >
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">Upload</h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="showUpload = false"
          />
        </div>
        <b-form class="p-2" @submit.prevent>
          <b-row>
            <b-col md="12">
              <b-form-group
                label="Collection Account"
                invalid-feedback="Collection account is required."
                ref="up_acc"
              >
                <v-select
                  v-model="uploadObj.accountID"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="accTypes"
                  :reduce="(opt) => opt.id"
                  label="account"
                  :clearable="false"
                  ref="up_acc"
                  placeholder="Select account type"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-button
                :variant="uploadObj.isTransport ? 'primary' : 'outline-primary'"
                block
                @click="uploadObj.isTransport = true"
              >
                Transport
              </b-button>
            </b-col>
            <b-col md="6" class="pl-md-0">
              <b-button
                :variant="
                  !uploadObj.isTransport ? 'primary' : 'outline-primary'
                "
                block
                @click="uploadObj.isTransport = false"
              >
                Tuition Fee / Other
              </b-button>
            </b-col>

            <b-col md="12" class="mt-2">
              <!-- <b-button
                v-if="uploadedData.length > 0"
                variant="outline-primary"
                block
                @click="uploadedData = []"
              >
                Clear
              </b-button> -->
              <b-button
                variant="primary"
                @click="$refs.fileProfile.click()"
                :disabled="importing"
                block
              >
                <input
                  type="file"
                  hidden
                  ref="fileProfile"
                  @change="uploadNew()"
                  accept=".xls,.xlsx"
                />
                <!-- @change="uploadOther()" -->
                <b-spinner v-if="importing" small type="grow" />
                <span v-else>Upload</span>
              </b-button>
            </b-col>

            <b-col md="12" class="mt-2">
              <b-button
                variant="success"
                @click="$refs.validProfile.click()"
                :disabled="validating"
                block
              >
                <input
                  type="file"
                  hidden
                  ref="validProfile"
                  @change="uploadValidate()"
                  accept=".xls,.xlsx"
                />
                <b-spinner v-if="validating" small type="grow" />
                <span v-else>Validate File</span>
              </b-button>
            </b-col>

            <!-- <b-col md="12" class="mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="success"
                :disabled="savingUpload"
                block
                @click="saveUpload()"
              >
                <b-spinner v-if="savingUpload" small type="grow" />
                <span v-else>Save</span>
              </b-button>
            </b-col> -->
          </b-row>
        </b-form>
        <sidebar-content />
      </b-sidebar>

      <b-sidebar
        id="sidebar-right"
        ref="mySidebar"
        bg-variant="white"
        v-model="showSettings"
        sidebar-class="sidebar-lg"
        right
        no-header
        backdrop
        shadow
      >
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">Settings</h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="showSettings = false"
          />
        </div>
        <b-form class="p-2" @submit.prevent>
          <b-row>
            <b-col md="4" class="pr-0">
              <b-form-checkbox id="checkbox1" v-model="settings.printSlip">
                <h5 class="">Print Slip</h5>
              </b-form-checkbox>
            </b-col>
            <b-col md="4" class="pr-0">
              <b-form-checkbox id="checkbox2" v-model="settings.sendSMS">
                <h5 class="">Send SMS</h5>
              </b-form-checkbox>
            </b-col>
            <b-col md="4" v-if="noticeObj" class="pr-0">
              <b-form-checkbox v-model="noticeObj.valueBool">
                <h5 class="">{{ noticeObj.value }}</h5>
              </b-form-checkbox>
            </b-col>
            <b-col md="12" v-if="cHeadObj" class="mt-1">
              <b-form-checkbox v-model="cHeadObj.valueBool">
                <h5 class="">{{ cHeadObj.value }}</h5>
              </b-form-checkbox>
            </b-col>

            <b-col md="12" class="mt-1">
              <b-form-group
                label="Print by"
                invalid-feedback="Print by is required."
                ref="printby"
              >
                <v-select
                  ref="printby"
                  v-model="settings.printBy"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="printOptions"
                  :clearable="false"
                  label="text"
                  :reduce="(val) => val.value"
                  placeholder="Select"
                />
              </b-form-group>
            </b-col>

            <b-col md="12" class="" v-if="subObj && subObj.valueBool">
              <b-form-group
                invalid-feedback="Fees to Print is required."
                ref="to_print"
              >
                <div class="d-flex justify-content-between pb-50">
                  <label class="bv-no-focus-ring col-form-label p-0"
                    >Fees to Print</label
                  >
                  <b-badge
                    variant="primary"
                    pill
                    class="cursor-pointer"
                    @click="toPrint = []"
                    >Clear All</b-badge
                  >
                </div>
                <v-select
                  class="set_multi"
                  ref="to_print"
                  multiple
                  :closeOnSelect="false"
                  v-model="toPrint"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="[{ id: 0, feeType: 'All' }, ...allfeeType]"
                  label="feeType"
                  :reduce="(val) => val.id"
                  placeholder="Select"
                  @input="setAll()"
                />
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="Issue Date"
                invalid-feedback="issue date is required."
                ref="issue"
              >
                <flat-pickr
                  ref="issue"
                  :config="config"
                  v-model="settings.issueDate"
                  class="form-control"
                  placeholder="Select Date."
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Due Date"
                invalid-feedback="due date is required."
                ref="due"
              >
                <flat-pickr
                  ref="due"
                  :config="config"
                  v-model="settings.dueDate"
                  class="form-control"
                  placeholder="Select Date."
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Expiry Date"
                invalid-feedback="expiry date is required."
                ref="exp"
              >
                <flat-pickr
                  ref="exp"
                  :config="config"
                  v-model="settings.expiryDate"
                  class="form-control"
                  placeholder="Select Date."
                />
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="Late fee"
                invalid-feedback="Late fee is required."
                ref="late"
              >
                <b-form-input
                  type="number"
                  v-model="settings.lateFee"
                  placeholder="Enter late fee"
                />
              </b-form-group>
            </b-col>

            <b-col md="12">
              <!-- label="Timely Discount" -->
              <b-form-group
                invalid-feedback="timely discount is required."
                ref="dis"
              >
                <div class="d-flex justify-content-between pb-50">
                  <label class="bv-no-focus-ring col-form-label p-0"
                    >Timely Discount</label
                  >
                  <span
                    >Till:
                    <b-badge variant="primary" pill>
                      {{
                        new Date(settings.dueDate).toLocaleDateString("en-UK", {
                          year: "numeric",
                          day: "numeric",
                          month: "short",
                        })
                      }}
                    </b-badge>
                  </span>
                </div>
                <b-form-input
                  type="number"
                  v-model="settings.timelyDiscount"
                  placeholder="Enter timely discount"
                />
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="Bank Name"
                invalid-feedback="Bank Name is required"
                ref="bank"
              >
                <b-form-input
                  v-model="settings.bankName"
                  placeholder="Enter bank name"
                />
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="Title of account"
                invalid-feedback="Title of account is required"
                ref="acc_title"
              >
                <b-form-input
                  v-model="settings.titleOfAccount"
                  placeholder="Enter title of account"
                />
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="Account Number"
                invalid-feedback="Account Number is required"
                ref="accNo"
              >
                <b-form-input
                  v-model="settings.accountNumber"
                  placeholder="Enter account number"
                />
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="Fee Challan Instructions"
                invalid-feedback="Please enter fee challan instruction"
                ref="instr"
              >
                <b-form-textarea
                  ref="instr"
                  placeholder="Please write instruction for fee challan."
                  v-model="settings.instructions"
                  rows="4"
                ></b-form-textarea>
              </b-form-group>
            </b-col>

            <b-col md="12" class="">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="success"
                @click="saveSettings()"
                :disabled="request"
                block
              >
                <b-spinner v-if="request" small type="grow" />
                <span v-if="request == false"> {{ sidebarButton }} </span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        <sidebar-content />
      </b-sidebar>

      <b-sidebar
        id="sidebar-right"
        ref="mySidebar"
        bg-variant="white"
        v-model="showDiscount"
        sidebar-class="sidebar-lg"
        right
        no-header
        backdrop
        shadow
      >
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">Discount</h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hideDiscount()"
          />
        </div>
        <b-form class="p-2" @submit.prevent>
          <b-row class="">
            <b-col md="12">
              <b-form-group label="Current Balance" ref="currBal">
                <b-form-input
                  disabled
                  placeholder=""
                  ref="currBal"
                  v-model="currentBalance"
                />
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="Discount"
                invalid-feedback="Invalid amount entered."
                ref="discount"
              >
                <b-form-input
                  id="mc-first-name"
                  type="number"
                  placeholder="Enter discount amount"
                  ref="discount"
                  v-model="disObj.discountAmount"
                  @input="validateDiscount()"
                />
              </b-form-group>
            </b-col>

            <b-col md="12" v-if="settings.timelyDiscount > 0">
              <b-form-group
                label="Timely Discount"
                invalid-feedback="Invalid amount entered."
                ref="timely"
              >
                <b-form-input
                  type="number"
                  placeholder="Enter discount amount"
                  ref="timely"
                  v-model="disObj.timelyDiscount"
                  @input="validateTimely()"
                />
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group label="Net Balance" ref="netBal">
                <b-form-input
                  disabled
                  placeholder=""
                  ref="netBal"
                  v-model="netBalance"
                />
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="Note"
                invalid-feedback="Note is required."
                ref="dis_note"
              >
                <b-form-textarea
                  placeholder="Enter note"
                  rows="3"
                  v-model="disObj.discountNote"
                />
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="success"
                class="mt-1"
                @click="AddDiscount()"
                :disabled="request"
                block
              >
                <b-spinner v-if="request" small type="grow" />
                <span v-else> {{ sidebarButton }} </span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        <sidebar-content />
      </b-sidebar>

      <b-sidebar
        id="sidebar-right"
        ref="mySidebar"
        bg-variant="white"
        v-model="visibility2"
        sidebar-class="sidebar-lg"
        right
        no-header
        backdrop
        shadow
      >
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">Installments</h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="visibility2 = false"
          />
        </div>
        <b-form class="p-2" @submit.prevent>
          <b-row class="mt-1">
            <b-col md="12">
              <b-form-group label="Current Balance" ref="currBal">
                <b-form-input
                  disabled
                  placeholder=""
                  ref="currBal"
                  v-model="instCurrent"
                />
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="Installment Amount"
                invalid-feedback="Invalid amount entered."
                ref="instAmount"
              >
                <b-form-input
                  id="mc-first-name"
                  type="number"
                  placeholder="Enter amount"
                  ref="instAmount"
                  v-model="instObj.amount"
                  @input="checkInstallment()"
                />
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="success"
                @click="saveInstallment()"
                :disabled="request"
                block
              >
                <b-spinner v-if="request" small type="grow" />
                <span v-else>Create Installment</span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        <sidebar-content />
      </b-sidebar>

      <b-sidebar
        id="sidebar-Fee"
        bg-variant="white"
        v-model="visibility"
        sidebar-class="sidebar-lg"
        right
        no-header
        backdrop
        shadow
      >
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">
            {{ sidebarTitle }}
          </h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hideSideBar()"
          />
        </div>
        <b-form class="p-2" @submit.prevent>
          <b-row class="">
            <b-col md="12">
              <b-form-group
                label="Status"
                invalid-feedback="Status is required."
              >
                <v-select
                  v-model="searchStatus"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="statusOptions"
                  :clearable="false"
                  label="text"
                  :reduce="(opt) => opt.value"
                  placeholder="Select year"
                />
              </b-form-group>
            </b-col>
            <b-col md="12" class="mb-1">
              <b-input-group>
                <b-input-group-prepend
                  is-text
                  @click="loadSearch()"
                  style="cursor: pointer"
                >
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>

                <b-form-input
                  v-model="searchQuery"
                  @keyup.enter="loadSearch()"
                  placeholder="Search by name or gr# or contact"
                >
                </b-form-input>
              </b-input-group>
            </b-col>
            <b-col md="12" class="mb-1">
              <b-input-group>
                <b-input-group-prepend
                  is-text
                  @click="loadbyFather()"
                  style="cursor: pointer"
                >
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>

                <b-form-input
                  v-model="searchFather"
                  @keyup.enter="loadbyFather()"
                  placeholder="Search by father name"
                >
                </b-form-input>
              </b-input-group>
            </b-col>
          </b-row>
        </b-form>
        <!-- <div
          class="d-flex justify-content-center mb-3"
          v-if="dataLoading"
          style="margin-top: 50px"
        >
          <b-spinner
            style="width: 3rem; height: 3rem"
            type="grow"
            variant="primary"
            label="Spinning"
          ></b-spinner>
        </div> -->
        <b-table
          class=""
          show-empty
          :busy="studentLoading"
          ref="studentSelect"
          :items="searchData"
          :fields="fields2"
          responsive
          hover
          selectable
          select-mode="single"
          @row-selected="onRowSelected"
        >
          <template #table-busy>
            <div
              class="d-flex justify-content-center mb-3"
              style="margin-top: 50px"
            >
              <b-spinner
                style="width: 3rem; height: 3rem"
                type="grow"
                variant="primary"
                label="Spinning"
              ></b-spinner>
            </div>
          </template>
          <!-- <template #head(student)="data">
            <div class="text-center">
              <span>{{ data.label }}</span>
            </div>
          </template> -->
          <template #cell(student)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="48"
                  :src="data.item.picture"
                  variant="light-primary"
                />
              </template>

              <b-badge variant="light-primary">
                {{ data.item.name }}
              </b-badge>

              <span
                class="font-weight-bold d-block text-nowrap"
                style="margin: 4px 0px"
              >
                <b-badge variant="light-primary">
                  F/N: {{ data.item.father_name }}
                </b-badge>
              </span>

              <small class="text-muted">
                <b-badge variant="light-primary">
                  {{
                    reportDomain === "myskoolhpgs" ||
                    reportDomain === "myskoolprofectus"
                      ? "CID"
                      : "GR"
                  }}
                  - {{ data.item.grNo }}
                </b-badge>
                <b-badge variant="light-primary" style="margin-inline: 10px">
                  {{ data.item.meta_class }}
                </b-badge>
              </small>
            </b-media>
          </template>
          <template #cell(class)="data">
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.meta_class }}
            </span>
          </template>
          <template #cell(details)="data">
            <p>
              <b><span>Name: </span> </b>{{ data.item.name }}
            </p>
            <p>
              <b><span>Father: </span></b> {{ data.item.father_name }}
            </p>
            <p>
              <b><span>Class: </span></b> {{ data.item.meta_class }}
            </p>
          </template>
        </b-table>
        <sidebar-content />
      </b-sidebar>

      <b-sidebar
        id="sidebar-Fee"
        bg-variant="white"
        v-model="accountbar"
        sidebar-class="sidebar-lg"
        right
        no-header
        backdrop
        shadow
      >
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">Collection Account</h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="accountbar = false"
          />
        </div>

        <b-form class="p-2" @submit.prevent>
          <!-- <b-row class="mt-1" v-if="!addingAcc">
            <b-col md="12">
              <b-button variant="primary" @click="addingAcc = true">
                Add Account
              </b-button>
            </b-col>
          </b-row> -->
          <b-row class="d-flex align-items-center">
            <b-col md="12">
              <b-form-group
                label="Account Name"
                invalid-feedback="Account name is required."
              >
                <b-form-input
                  ref="accname"
                  v-model.trim="accountObj.account"
                  placeholder="Enter collection account name"
                  @focusout="checkAccount()"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <!-- v-if="entryObj && entryObj.valueBool" -->
            <b-col md="12">
              <b-form-group
                label="Link with"
                invalid-feedback="Account is required."
              >
                <v-select
                  ref="coa"
                  v-model="accountObj.accountID"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="coAccounts"
                  :reduce="(opt) => opt.id"
                  :clearable="true"
                  label="title"
                  placeholder="Select account"
                  @input="setCOA"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-button
                variant="success"
                class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                @click="saveAcc()"
                :disabled="savingacc"
                block
              >
                <b-spinner v-if="savingacc" small type="grow" />
                <!-- <feather-icon v-else :icon="accIcon" /> -->
                <span v-else>Save</span>
              </b-button>
            </b-col>
          </b-row>
          <b-table
            class="mt-2"
            show-empty
            :items="accTypes"
            :fields="accFields"
            responsive
            hover
          >
            <template #head(actions)="data">
              <div class="text-right">
                <span>{{ data.label }}</span>
              </div>
            </template>

            <template #cell(actions)="data">
              <div class="text-right">
                <b-button
                  variant="primary"
                  class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                  @click="editAcc(data.item)"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
                <b-button
                  variant="outline-danger"
                  class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                  @click="deleteAcc(data.item)"
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </div>
            </template>
          </b-table>
          <!-- <b-row>
            <b-col
              style="border-bottom: 2px solid lightgray"
              v-if="accTypes.length > 0"
              class="d-flex align-items-center justify-content-between mb-1 pb-1 pt-1"
              md="12"
              v-for="acc in accTypes"
              :key="acc.id"
            >
              <h6 class="mr-1">{{ acc.account }}</h6>
              <div>
                <b-button
                  variant="primary"
                  class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                  @click="editAcc(acc)"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
                <b-button
                  variant="outline-danger"
                  class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                  @click="deleteAcc(acc)"
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </div>
            </b-col>
            <b-col md="12" v-else class="text-center">
              <h4>No Records Found</h4>
            </b-col>
          </b-row> -->
        </b-form>
        <sidebar-content />
      </b-sidebar>

      <b-sidebar
        id="sidebar-right"
        ref="mySidebar"
        bg-variant="white"
        v-model="visibility3"
        sidebar-class="sidebar-lg"
        right
        no-header
        backdrop
        shadow
      >
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">Hold Fees</h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="visibility3 = false"
          />
        </div>
        <b-form class="p-2" @submit.prevent>
          <b-row class="mt-1">
            <b-col md="12">
              <b-form-group
                label="Date"
                invalid-feedback="Date is required"
                ref="commit_date"
              >
                <flat-pickr
                  ref="commit_date"
                  :config="config"
                  v-model="commitObj.date"
                  class="form-control"
                  placeholder="Select Date"
                />
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="Reason"
                invalid-feedback="Reason"
                ref="reason"
              >
                <b-form-textarea
                  ref="reason"
                  placeholder="Write reason for holding"
                  v-model="commitObj.reason"
                  rows="4"
                ></b-form-textarea>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                @click="saveCommitment()"
                :disabled="pSaving"
                block
              >
                <b-spinner v-if="pSaving" small type="grow" />
                <span v-else> Save </span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        <sidebar-content />
      </b-sidebar>

      <b-sidebar
        id="sidebar-right"
        ref="mySidebar"
        bg-variant="white"
        v-model="hisOpen"
        sidebar-class="sidebar-lg"
        right
        no-header
        backdrop
        shadow
      >
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">Fee History</h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hisOpen = false"
          />
        </div>
        <b-form class="p-2" @submit.prevent>
          <b-row class="mt-1">
            <b-col md="12">
              <b-form-group
                label="Date"
                invalid-feedback="Date is required"
                ref="his_date"
              >
                <flat-pickr
                  ref="his_date"
                  :config="config2"
                  v-model="rangeDate"
                  class="form-control"
                  placeholder="Select Date"
                  @on-change="setHisDate()"
                />
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="printHistory()"
                :disabled="hisPrinting"
                block
              >
                <b-spinner v-if="hisPrinting" small type="grow" />
                <span v-else>Show</span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        <sidebar-content />
      </b-sidebar>

      <b-sidebar
        id="sidebar-Fee"
        bg-variant="white"
        v-model="visibility4"
        sidebar-class="sidebar-lg"
        right
        no-header
        backdrop
        shadow
      >
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">Manage Organizations</h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="visibility4 = false"
          />
        </div>

        <b-form class="p-2" @submit.prevent>
          <b-row class="d-flex align-items-center">
            <b-col md="12">
              <b-form-group
                label="Organization Name"
                invalid-feedback="Organization name is required."
              >
                <b-form-input
                  ref="org_name"
                  v-model.trim="orgObj.title"
                  placeholder="Enter organization name name"
                  @focusout="checkOrg()"
                >
                </b-form-input>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-button
                variant="success"
                class="btn-icon mt-1"
                @click="saveOrg()"
                :disabled="savingOrg"
                block
              >
                <b-spinner v-if="savingOrg" small type="grow" />
                <span v-else>Save</span>
              </b-button>
            </b-col>
          </b-row>
          <b-table
            class="mt-2"
            show-empty
            :items="orgList"
            :fields="orgFields"
            responsive
            hover
          >
            <template #head(actions)="data">
              <div class="text-right">
                <span>{{ data.label }}</span>
              </div>
            </template>

            <template #cell(actions)="data">
              <div class="text-right">
                <b-button
                  variant="primary"
                  class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                  @click="editOrg(data.item)"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
                <!-- <b-button
                  variant="outline-danger"
                  class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                  @click="deleteOrg(data.item.id)"
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button> -->
              </div>
            </template>
          </b-table>
        </b-form>
        <sidebar-content />
      </b-sidebar>

      <!-- Table Top -->
      <b-col md="9" style="padding: 0">
        <b-card>
          <b-row>
            <b-col md="12" v-if="createForm">
              <b-card class="card mt-1 rightCard">
                <h3>Create Fee Collection</h3>
                <hr />
                <b-row>
                  <b-col md="12">
                    <b-form-group
                      label="Select Fee Type"
                      invalid-feedback="fee type is required."
                      ref="type"
                    >
                      <a class="my-buttons font-weight-bold">
                        <span v-if="!showAll" @click="showAll = true"
                          >Show More</span
                        >
                        <span v-else @click="showAll = false">Show Less</span>
                      </a>
                      <div
                        :class="{ 'my-collapsing': !showAll }"
                        style="
                          border: 1px solid lightgray;
                          padding: 5px;
                          border-radius: 5px;
                        "
                      >
                        <b-button
                          style="margin: 5px"
                          class="btn-icon"
                          v-for="fee in allfeeType"
                          :key="fee.id"
                          :variant="
                            variantFeetype(fee.id)
                              ? 'primary'
                              : 'outline-primary'
                          "
                          @click="selectFeetype(fee.id, fee.recurrence)"
                        >
                          {{ fee.feeType }}
                        </b-button>
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="12"
                    class=""
                    v-if="subObj && subObj.valueBool && showOnBehalf"
                  >
                    <b-form-group
                      label="Late on Behalf of"
                      invalid-feedback="Please select fee type."
                      ref="late_type"
                    >
                      <a class="my-buttons font-weight-bold">
                        <span v-if="!showAllLate" @click="showAllLate = true"
                          >Show More</span
                        >
                        <span v-else @click="showAllLate = false"
                          >Show Less</span
                        >
                      </a>
                      <div
                        :class="{ 'my-collapsing': !showAllLate }"
                        style="
                          border: 1px solid lightgray;
                          padding: 5px;
                          border-radius: 5px;
                        "
                      >
                        <b-button
                          style="margin: 5px"
                          class="btn-icon"
                          v-for="fee in allfeeType.filter(
                            (el) => el.recurrence != 'after due date'
                          )"
                          :key="fee.id"
                          :variant="
                            collection.onBehalf == fee.id
                              ? 'primary'
                              : 'outline-primary'
                          "
                          @click="selectOnBehalf(fee.id)"
                        >
                          {{ fee.feeType }}
                        </b-button>
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col md="10">
                    <b-form-group
                      label="Select Months"
                      invalid-feedback="month is required."
                      ref="months"
                    >
                      <div
                        style="
                          border: 1px solid lightgray;
                          padding: 5px;
                          border-radius: 5px;
                        "
                      >
                        <b-button
                          style="margin: 7px"
                          class="btn-icon"
                          v-for="mon in months"
                          :key="mon.id"
                          :variant="
                            variantMonth(mon.name)
                              ? 'primary'
                              : 'outline-primary'
                          "
                          @click="selectMonth(mon.name)"
                        >
                          {{ mon.name }}
                        </b-button>
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col md="2" class="">
                    <b-form-group
                      label="Select Year *"
                      invalid-feedback="year is required."
                      ref="year"
                    >
                      <v-select
                        v-model="collection.year"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="years"
                        :clearable="false"
                        ref="year"
                        label="name"
                        placeholder="Select year"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col class="text-right mt-1">
                    <b-button variant="outline-secondary" @click="closeForm()">
                      Close
                    </b-button>
                    <b-button
                      variant="success"
                      :disabled="saving"
                      class="ml-1"
                      @click="saveFee()"
                    >
                      <div v-if="saving">
                        <b-spinner
                          small
                          variant="light"
                          type="grow"
                          label="Spinning"
                        ></b-spinner>
                      </div>
                      <span v-else>Save</span>
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>

            <b-col md="12" v-else>
              <b-row class="mt-1" style="display: flex; align-items: center">
                <b-col xl="7" lg="7" md="7" sm="12" cols="12" class="d-flex">
                  <!-- <b-form-group
                    v-if="schGr && schGr.valueBool"
                    :invalid-feedback="
                      searchType == 'grNo'
                        ? 'CID no is required.'
                        : 'GR no is required.'
                    "
                    class="mr-1"
                  >
                    <label class="m-0 pb-50">
                      <b-button
                        :variant="
                          searchType == 'grNo' ? 'primary' : 'outline-primary'
                        "
                        pill
                        class="btn-icon font-small-2 mr-50"
                        size="sm"
                        @click="searchType = 'grNo'"
                        >CID #</b-button
                      >
                      <b-button
                        :variant="
                          searchType == 'grNoLocal'
                            ? 'primary'
                            : 'outline-primary'
                        "
                        pill
                        class="btn-icon font-small-2"
                        size="sm"
                        @click="searchType = 'grNoLocal'"
                        >GR #</b-button
                      >
                    </label>
                    <b-form-input
                      v-model="myObj.gr"
                      class="form-control"
                      :placeholder="
                        searchType == 'grNo' ? 'Enter CID' : 'Enter GR No.'
                      "
                      @input="setLastChanged('gr')"
                      @keyup.enter="byGR()"
                      ref="grNo"
                    />
                  </b-form-group>
                   -->
                  <b-form-group
                    :label="
                      reportDomain === 'myskoolhpgs' ||
                      reportDomain === 'myskoolprofectus'
                        ? 'CID #'
                        : 'GR #'
                    "
                    :invalid-feedback="
                      reportDomain === 'myskoolhpgs' ||
                      reportDomain === 'myskoolprofectus'
                        ? 'CID no is required.'
                        : 'GR no is required.'
                    "
                    class="mr-1"
                  >
                    <b-form-input
                      v-model="myObj.gr"
                      class="form-control"
                      :placeholder="
                        reportDomain === 'myskoolhpgs' ||
                        reportDomain === 'myskoolprofectus'
                          ? 'Enter CID'
                          : 'Enter GR No.'
                      "
                      @input="setLastChanged('gr')"
                      @keyup.enter="byGR()"
                      ref="grNo"
                    />
                  </b-form-group>
                  <!-- <b-form-group
                    label="GR #"
                    invalid-feedback="GR no is required."
                    class="mr-1"
                  >
                    <b-form-input
                      v-model="myObj.gr"
                      class="form-control"
                      placeholder="Enter GR number"
                      @input="setLastChanged('gr')"
                      @keyup.enter="byGR()"
                      ref="grNo"
                    />
                  </b-form-group> -->
                  <b-form-group
                    label="Family code"
                    invalid-feedback="Family code is required."
                    class="mr-1"
                  >
                    <b-form-input
                      v-model="myObj.familyCode"
                      class="form-control"
                      placeholder="Enter family code"
                      ref="fCode"
                      @input="setLastChanged('family')"
                      @keyup.enter="byFamily()"
                    />
                  </b-form-group>
                  <b-form-group
                    label="Challan No"
                    invalid-feedback="Challan No is required."
                    class="mr-1"
                  >
                    <b-form-input
                      v-model="myObj.challanNo"
                      class="form-control"
                      type="number"
                      placeholder="Enter challan number"
                      ref="challan"
                      @input="setLastChanged('challan')"
                      @keyup.enter="byChallan()"
                    />
                  </b-form-group>
                  <b-form-checkbox
                    v-model="auto"
                    switch
                    class="mt-2"
                    v-b-tooltip.hover.top
                    title="Auto Received"
                  />
                </b-col>
                <!-- <b-col xl="2" lg="2" md="3" sm="12" cols="12">
                  <b-form-group
                    label="Family code"
                    invalid-feedback="Family code is required."
                  >
                    <b-form-input
                      v-model="myObj.familyCode"
                      class="form-control"
                      placeholder="Enter family code"
                      ref="fCode"
                      @keyup.enter="byFamily()"
                    />
                  </b-form-group>
                </b-col> -->
                <!-- <b-col
                  xl="3"
                  lg="4"
                  md="4"
                  sm="12"
                  cols="12"
                  class="d-flex pl-0"
                >
                  <b-form-group
                    label="Challan No"
                    invalid-feedback="Challan No is required."
                  >
                    <b-form-input
                      v-model="myObj.challanNo"
                      class="form-control"
                      type="number"
                      placeholder="Enter challan number"
                      ref="challan"
                      @keyup.enter="byChallan()"
                    />
                  </b-form-group>
                  <b-form-checkbox v-model="auto" switch class="ml-50" /> 
                </b-col> -->
                <b-col
                  xl="2"
                  lg="2"
                  md="2"
                  sm="12"
                  cols="12"
                  class="d-flex mb-50 mb-md-0 pl-md-0"
                >
                  <b-button
                    class="topButtons btn-icon mr-1"
                    style="width: 75%"
                    :disabled="dataLoading || gridLoading"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="show()"
                  >
                    <span class="align-middle">Show</span>
                  </b-button>
                  <b-button
                    class="topButtons btn-icon mr-1"
                    :disabled="dataLoading"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-primary"
                    @click="showSidebar()"
                  >
                    <feather-icon icon="SearchIcon" />
                  </b-button>
                </b-col>

                <b-col xl="3" lg="3" md="3" sm="12" cols="12" v-if="rights.add">
                  <b-button
                    class="topButtons btn-icon"
                    block
                    :disabled="dataLoading"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="showForm()"
                  >
                    <span class="align-middle">Create Fee</span>
                  </b-button>
                </b-col>
              </b-row>
              <b-row class="mt-1">
                <b-col md="6">
                  <b-button
                    v-if="receiveObj.feesIDs.length > 0"
                    variant="primary"
                    class="btn-icon mr-1"
                    @click="openCom()"
                    :disabled="unholding || pSaving"
                  >
                    Hold
                  </b-button>
                  <b-button
                    v-if="receiveObj.feesIDs.length > 0"
                    variant="primary"
                    class="btn-icon"
                    @click="unHoldFee()"
                    :disabled="unholding || pSaving"
                  >
                    UnHold
                  </b-button>
                </b-col>
                <b-col md="6" class="text-right">
                  <b-button
                    variant="outline-primary"
                    class="btn-icon"
                    @click="getReport()"
                  >
                    Print Report
                  </b-button>
                  <!-- <b-button
                    variant="outline-primary"
                    class="btn-icon ml-1"
                    @click="$refs.fileProfile.click()"
                    :disabled="importing"
                  >
                    <input
                      type="file"
                      hidden
                      ref="fileProfile"
                      @change="upload()"
                      accept=".xls,.xlsx"
                    />
                    Upload
                  </b-button> -->
                  <b-button
                    variant="outline-primary"
                    class="btn-icon ml-1"
                    @click="OpenModal()"
                  >
                    Upload
                  </b-button>
                  <b-button
                    variant="outline-primary"
                    class="btn-icon ml-1"
                    @click="openSlipModal()"
                    v-b-tooltip.hover.top
                    title="Print Slips"
                  >
                    <feather-icon icon="FileTextIcon" />
                  </b-button>

                  <!-- <b-button
                    variant="outline-primary"
                    class="btn-icon ml-1"
                    @click="showDeleted()"
                  >
                    Receipts
                  </b-button> -->
                </b-col>
                <b-col md="2">
                  <b-form-checkbox
                    v-if="false"
                    id="checkbox"
                    v-model="allSelect"
                    @input="checkSelect()"
                  >
                    <h5 class="" v-if="!allSelect">Select all</h5>
                    <h5 class="" v-else>Unselect all</h5>
                  </b-form-checkbox>
                </b-col>
              </b-row>
              <!-- <b-button @click="selectAllRows" variant="primary"
                >Select all</b-button
              >
              <b-button @click="clearSelected" variant="primary"
                >Clear selected</b-button
              > -->

              <b-table
                class="mt-1"
                :busy="gridLoading"
                show-empty
                ref="selectableTable"
                selectable
                :select-mode="'multi'"
                @row-selected="onChallanRowSelect($event)"
                :items="items"
                :fields="computedFields"
                responsive
                hover
                small
              >
                <template #table-busy>
                  <div
                    class="d-flex justify-content-center mb-3"
                    style="margin-top: 50px"
                  >
                    <b-spinner
                      style="width: 3rem; height: 3rem"
                      type="grow"
                      variant="primary"
                      label="Spinning"
                    ></b-spinner>
                  </div>
                </template>
                <template #head(actions)="data">
                  <div class="text-center">
                    <span>{{ data.label }}</span>
                  </div>
                </template>

                <template #cell(gr)="data">
                  {{ data.item.stdd.grNo }}
                </template>
                <template #cell(name)="data">
                  {{ data.item.stdd.name }}
                </template>
                <template #cell(class)="data">
                  {{ data.item.cls.name }} - {{ data.item.sec.section }}
                </template>
                <template #cell(feeType)="data">
                  {{ data.item.feesType.feeType }}
                </template>
                <template #cell(monthYear)="data">
                  <b-badge variant="light-primary">
                    {{ data.item.fees.monthYear }}
                  </b-badge>
                  <br />
                  <b-badge
                    variant="light-danger"
                    style="margin-top: 4px"
                    v-if="data.item.fees.status == 'hold'"
                  >
                    On Hold
                  </b-badge>
                </template>
                <template #cell(lateFee)="data">
                  <b-badge variant="light-primary">
                    {{ data.item.fees.lateFee }}
                  </b-badge>
                </template>
                <template #cell(timelyDiscount)="data">
                  <b-badge variant="light-primary">
                    {{ data.item.fees.timelyDiscount }}
                  </b-badge>
                </template>
                <template #cell(balance)="data">
                  <b-badge variant="light-primary">
                    {{ data.item.fees.balance }}
                  </b-badge>
                </template>

                <template #cell(actions)="data">
                  <div class="d-flex justify-content-center">
                    <b-button
                      v-if="
                        role.toLowerCase() == 'administrator' || rights.edit
                      "
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                      @click="openDiscount(data.item)"
                    >
                      <!-- <feather-icon icon="EditIcon" /> -->
                      Discount
                    </b-button>
                    <b-button
                      v-if="
                        role.toLowerCase() == 'administrator' || rights.edit
                      "
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-primary"
                      class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                      v-b-tooltip.hover.bottomleft
                      title="Installment"
                      @click="openInst(data.item)"
                    >
                      <feather-icon icon="ShuffleIcon" />
                    </b-button>
                    <b-button
                      v-if="
                        role.toLowerCase() == 'administrator' || rights.edit
                      "
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-primary"
                      class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                      v-b-tooltip.hover.bottomleft
                      title="Fee Exempt"
                      @click="openExempt(data.item.fees.id)"
                    >
                      <!-- @click="Exempt(data.item.fees.id)" -->
                      <feather-icon icon="StarIcon" />
                    </b-button>
                    <b-button
                      v-if="
                        role.toLowerCase() == 'administrator' || rights.delete
                      "
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-danger"
                      class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                      v-b-tooltip.hover.bottomright
                      title="Delete"
                      @click="deleteFee(data.item.fees.id)"
                    >
                      <feather-icon icon="Trash2Icon" />
                    </b-button>
                  </div>
                </template>
              </b-table>
              <!-- <span>{{ selected }}</span> -->
            </b-col>
          </b-row>
        </b-card>

        <b-card class="pl-1 pr-1">
          <b-row align-h="between">
            <div class="d-flex flex-wrap align-items-center">
              <h2 class="ml-1">
                Fee History
                <feather-icon
                  class="ml-50 cursor-pointer"
                  icon="PrinterIcon"
                  size="18"
                  @click="openPrint()"
                  v-b-tooltip.hover.right
                  title="Print"
                />
              </h2>
              <b-badge
                v-if="ledgObj && ledgObj.valueBool && showLedg"
                variant="success"
                class="ml-1 px-1 cursor-pointer"
                pill
                @click="openLedger()"
              >
                View Previous Ledger
              </b-badge>
            </div>
            <feather-icon
              v-if="!$store.state.history"
              style="cursor: pointer"
              icon="ArrowDownIcon"
              size="28"
              @click="showHistory()"
            />
            <feather-icon
              v-else
              style="cursor: pointer"
              icon="ArrowUpIcon"
              size="28"
              @click="hideHistory()"
            />
          </b-row>

          <b-collapse id="collapse-1" v-model="$store.state.history">
            <hr class="border-top-dark" />
            <b-table
              class="mt-1"
              :busy="historyLoading"
              show-empty
              :items="history"
              :fields="hisFields"
              responsive
              hover
              small
            >
              <template #table-busy>
                <div
                  class="d-flex justify-content-center mb-3"
                  style="margin-top: 50px"
                >
                  <b-spinner
                    style="width: 3rem; height: 3rem"
                    type="grow"
                    variant="primary"
                    label="Spinning"
                  ></b-spinner>
                </div>
              </template>
              <!-- <template #head(actions)="data">
                <div class="mr-5 text-right">
                  <span>{{ data.label }}</span>
                </div>
              </template> -->

              <template #cell(billID)="data">
                <b-badge
                  v-if="data.item.fees.billID <= 0"
                  variant="light-danger"
                >
                  {{ data.item.fees.status }}
                </b-badge>
                <b-badge variant="light-primary" v-else>
                  {{ data.item.fees.billID }}
                </b-badge>
              </template>
              <template #cell(rcvdDate)="data">
                <b-badge variant="light-primary">
                  <!-- {{ data.item.fees.rcvdDate.split("T")[0] }} -->
                  <!-- {{
                    new Date(data.item.fees.rcvdDate)
                      .toDateString()
                      .split(" ")
                      .slice(1)
                      .join(" ")
                  }} -->
                  {{
                    new Date(data.item.fees.rcvdDate).toLocaleDateString(
                      "en-UK",
                      {
                        year: "numeric",
                        day: "numeric",
                        month: "short",
                      }
                    )
                  }}
                </b-badge>
              </template>
              <template #cell(feeType)="data">
                {{ data.item.feesType.feeType }}
              </template>
              <template #cell(class)="data">
                {{ data.item.cls.name }} - {{ data.item.sec.section }}
              </template>
              <template #cell(monthYear)="data">
                <b-badge variant="light-primary">
                  {{ data.item.fees.monthYear }}
                </b-badge>
              </template>
              <template #cell(rcvd)="data">
                <b-badge variant="light-primary">
                  {{
                    data.item.fees.billID == 0
                      ? data.item.fees.exempted
                      : data.item.fees.rcvd
                  }}
                </b-badge>
              </template>
              <template #cell(rcvdBy)="data">
                <b-badge variant="light-primary">
                  {{ getUser(data.item.fees.rcvdBy) }}
                </b-badge>
              </template>

              <template #cell(actions)="data">
                <!-- class="mr-3 text-right" -->
                <div class="d-flex">
                  <b-button
                    v-if="data.item.fees.billID > 0"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                    v-b-tooltip.hover.bottomleft
                    title="Print"
                    @click="printSlip(data.item.fees.billID, total)"
                  >
                    <feather-icon icon="PrinterIcon" />
                  </b-button>

                  <!-- <b-button
                    v-if="data.item.fees.billID > 0"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-primary"
                    class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                    v-b-tooltip.hover.bottomleft
                    title="Send Slip"
                    @click="sendSlip(data.item.fees.billID)"
                    :disabled="sending"
                  >
                    <feather-icon icon="MessageCircleIcon" />
                  </b-button> -->

                  <b-button
                    v-if="
                      (role.toLowerCase() == 'administrator' ||
                        rights.delete) &&
                      data.item.fees.billID == 0
                    "
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-danger"
                    class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                    v-b-tooltip.hover.bottomright
                    title="Unexempt"
                    @click="UnExempt(data.item.fees.id)"
                  >
                    <feather-icon icon="XIcon" />
                  </b-button>

                  <b-button
                    v-else-if="
                      (role.toLowerCase() == 'administrator' ||
                        rights.delete) &&
                      data.item.fees.billID > 0
                    "
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-danger"
                    class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                    v-b-tooltip.hover.bottomright
                    title="Cancel"
                    @click="CancelFee(data.item.fees.billID)"
                  >
                    <feather-icon icon="XIcon" />
                  </b-button>
                </div>
              </template>
            </b-table>
          </b-collapse>
        </b-card>
      </b-col>

      <b-col md="3" style="padding: 0; padding-left: 1.2rem">
        <b-card class="">
          <b-row align-h="end">
            <b-col md="2">
              <b-spinner small v-if="loadSetting" />
              <feather-icon
                v-else
                icon="SettingsIcon"
                style="width: 20px; height: 20px; cursor: pointer"
                v-b-tooltip.hover.left
                title="Settings"
                @click="openSettings(true)"
              />
            </b-col>
          </b-row>
          <br />
          <b-row align-h="center" v-if="fromGr">
            <b-img
              v-if="myObj.pic == null || myObj.pic == ''"
              src="https://cdn.cloudious.net/file-1677912450367-333289208.png"
              style="
                width: 70px;
                height: 70px;
                object-fit: cover;
                border-radius: 50%;
              "
            />
            <b-img
              v-else
              :src="myObj.pic"
              style="
                width: 70px;
                height: 70px;
                object-fit: cover;
                border-radius: 50%;
              "
            />
          </b-row>

          <b-row align-h="center" v-else>
            <b-col md="6">
              <swiper
                ref="myswiper"
                class="swiper-navigations"
                :options="swiperOptions"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                @slideChange="slidechange"
              >
                <swiper-slide v-for="(data, index) in students" :key="index">
                  <div style="display: flex; justify-content: center">
                    <b-img
                      v-if="data.pic"
                      :src="data.pic"
                      fluid
                      style="
                        width: 70px;
                        height: 70px;
                        object-fit: cover;
                        border-radius: 50%;
                      "
                    />
                    <b-img
                      v-else
                      src="https://cdn.cloudious.net/file-1677912450367-333289208.png"
                      fluid
                      style="
                        width: 70px;
                        height: 70px;
                        object-fit: cover;
                        border-radius: 50%;
                      "
                    />
                  </div>
                </swiper-slide>

                <div slot="button-next" class="swiper-button-next my-btn" />
                <div slot="button-prev" class="swiper-button-prev my-btn" />
              </swiper>
            </b-col>
          </b-row>
          <br />
          <b-row>
            <b-col v-if="myObj.name">
              <h5 style="text-align: center">
                <span class="label">{{ myObj.name }}</span>
              </h5>
              <hr />
            </b-col>
          </b-row>
          <b-row>
            <b-col v-if="myObj.meta_class">
              <h5 style="text-align: center">
                <span class="label mb-50"
                  >{{ myObj.meta_class }} - {{ myObj.meta_section }}</span
                >
              </h5>
              <h5 class="text-center mb-0">
                <b-badge variant="light-primary">{{ myObj.status }}</b-badge>
              </h5>
              <hr />
            </b-col>
          </b-row>
          <b-row>
            <b-col md="8">
              <b-button
                class="mt-1 btn-icon"
                block
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-primary"
                @click="checkPrint('print')"
                :disabled="iPrint"
              >
                <b-spinner v-if="iPrint" small type="grow" />
                <feather-icon v-if="!iPrint" icon="PrinterIcon" class="mr-50" />
                <span v-if="!iPrint">Print</span>
              </b-button>
            </b-col>
            <b-col md="4" class="pl-md-0">
              <b-button
                class="mt-1 btn-icon"
                block
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-primary"
                :disabled="iMsg"
                @click="checkPrint('whatsapp')"
              >
                <b-spinner v-if="iMsg" small type="grow" />
                <feather-icon v-if="!iMsg" icon="MailIcon" class="" />
              </b-button>
            </b-col>
          </b-row>
          <br />
          <div style="background-color: none; padding: 15px 0px">
            <!-- label="Total dues" -->
            <b-form-group invalid-feedback="Total is required.">
              <div class="d-flex justify-content-between pb-50">
                <label class="bv-no-focus-ring col-form-label p-0"
                  >Total dues</label
                >
                <!-- <b-badge variant="primary">
                  {{ settings.timelyDiscount }}
                </b-badge> -->
              </div>
              <b-form-input
                disabled
                v-model="total"
                class="form-control"
                placeholder="Total dues"
              />
            </b-form-group>

            <b-form-group
              v-if="false"
              label="Late fee"
              invalid-feedback="Late fee is required."
              ref="late"
            >
              <b-form-input
                disabled
                type="number"
                v-model="lateFee"
                placeholder="Enter late fee"
              />
            </b-form-group>

            <b-form-group
              label="Receiving amount"
              invalid-feedback="Invalid amount entered"
            >
              <b-form-input
                ref="paid"
                type="number"
                v-model="paid"
                @input="getBalance()"
                class="form-control"
                placeholder="Receiving amount"
              />
            </b-form-group>
            <b-form-group
              label="Balance"
              invalid-feedback="Balance is required."
            >
              <b-form-input
                disabled
                v-model="balance"
                class="form-control"
                placeholder="Balance"
                ref="fCode"
              />
            </b-form-group>

            <!-- label="After Timely Discount" -->
            <b-form-group
              v-if="dueDateToShow && discountToShow > 0"
              invalid-feedback="timely discount is required."
              ref="dis"
            >
              <div
                class="d-flex justify-content-between align-items-start pb-50"
              >
                <label class="bv-no-focus-ring col-form-label p-0"
                  >After Timely Dis (Rs.{{
                    new Date(receiveObj.rcvDate) <= new Date(dueDateToShow)
                      ? discountToShow
                      : advanceToShow
                  }})</label
                >
                <b-badge variant="primary" pill class="cursor-pointer">
                  {{
                    new Date(dueDateToShow).toLocaleDateString("en-UK", {
                      year: "numeric",
                      day: "numeric",
                      month: "short",
                    })
                  }}
                </b-badge>
              </div>
              <b-form-input
                disabled
                type="number"
                v-model="afterDis"
                placeholder="Enter timely discount"
              />
              <!-- v-model="timelyDiscount" -->
            </b-form-group>

            <b-form-group
              invalid-feedback="Collection account is required."
              ref="acc"
            >
              <div class="d-flex justify-content-between">
                <label class="bv-no-focus-ring col-form-label pt-0"
                  >Collection Account</label
                >
                <feather-icon
                  v-if="rights.add"
                  class="cursor-pointer"
                  icon="BriefcaseIcon"
                  size="20"
                  v-b-tooltip.hover.top
                  title="Accounts"
                  @click="openAccount()"
                />
              </div>
              <v-select
                v-model="accountType"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="accTypes"
                :reduce="(opt) => opt.id"
                label="account"
                :clearable="false"
                ref="acc"
                placeholder="Select account type"
                @input="setAccount()"
                @search:blur="checkRecAccount()"
              />
            </b-form-group>
            <b-form-group
              label="Receiving Date"
              invalid-feedback="receiving date is required."
              ref="rcvDate"
            >
              <flat-pickr
                ref="rcvDate"
                :config="config"
                v-model="receiveObj.rcvDate"
                class="form-control"
                placeholder="Select Date."
                :disabled="
                  (!rights.add && !rights.edit) ||
                  rights.disableDate ||
                  (backDtObj && backDtObj.valueBool)
                "
                @on-change="checkDate()"
              />
            </b-form-group>

            <b-form-checkbox
              v-if="lateObj && lateObj.valueBool"
              v-model="withLate"
            >
              <span class="bv-no-focus-ring col-form-label p-0">
                Receive with Late Fee
              </span>
            </b-form-checkbox>

            <template v-if="chDetails && chDetails.valueBool">
              <b-form-group invalid-feedback="Organization is required.">
                <div class="d-flex justify-content-between">
                  <label class="bv-no-focus-ring col-form-label pt-0"
                    >Organization</label
                  >
                  <feather-icon
                    v-if="rights.add"
                    class="cursor-pointer"
                    icon="EditIcon"
                    size="20"
                    v-b-tooltip.hover.top
                    title="Organization"
                    @click="openOrg()"
                  />
                </div>
                <v-select
                  v-model="otherObj.organization"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="[{ id: 0, title: 'None' }, ...orgList]"
                  :reduce="(opt) => opt.id"
                  label="title"
                  :clearable="false"
                  placeholder="Select organization"
                />
              </b-form-group>
              <b-form-group
                label="Bank Name"
                invalid-feedback="Bank Name is required."
              >
                <b-form-input
                  v-model="otherObj.bank"
                  placeholder="Enter bank name"
                />
              </b-form-group>
              <b-form-group
                label="Cheque No."
                invalid-feedback="Cheque No is required."
              >
                <b-form-input
                  v-model="otherObj.cheque"
                  placeholder="Enter cheque number"
                />
              </b-form-group>
            </template>

            <b-form-group
              label="Note"
              invalid-feedback="Note is required."
              ref="rec_note"
            >
              <b-form-textarea
                placeholder="Enter note"
                rows="3"
                v-model="receiveObj.receiveNote"
              />
            </b-form-group>

            <b-button
              class="mt-1"
              block
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              :disabled="
                receiving ||
                dataLoading ||
                gridLoading ||
                (!rights.add && !rights.receiveFee)
              "
              @click="receiveFee('normal')"
            >
              <b-spinner v-if="receiving" small type="grow" />
              <span v-else class="align-middle">Receive</span>
            </b-button>
          </div>
        </b-card>
      </b-col>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCollapse,
  BCarouselSlide,
  BCarousel,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormGroup,
  BForm,
  BContainer,
  BMediaAside,
  BSpinner,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BInputGroup,
  BFormTextarea,
  BAvatar,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

//import { ref, onUnmounted } from '@vue/composition-api'
//import useAllOrdersList from './useAllOrdersList'
//import orderStoreModule from '../allordersStoreModule'
import flatPickr from "vue-flatpickr-component";
//import Multiselect from "vue-multiselect";
import { TheMask } from "vue-the-mask";
import { mapActions, mapGetters } from "vuex";
import swiper from "swiper";
import readXlsxFile from "read-excel-file";
import * as XLSX from "xlsx";
import axios from "axios";

export default {
  components: {
    //Multiselect,
    BFormTextarea,
    Swiper,
    SwiperSlide,
    flatPickr,
    BCarouselSlide,
    BCarousel,
    BCollapse,
    TheMask,
    BInputGroup,
    BCard,
    BRow,
    BBadge,
    BTable,
    BCol,
    BAvatar,
    BFormInput,
    BButton,
    BSidebar,
    BFormGroup,
    BInputGroupPrepend,
    BSpinner,
    BFormCheckbox,
    BFormCheckboxGroup,
    BForm,
    BContainer,
    //BCardTitle,
    //BCardText,
    BMediaAside,
    BImg,
    BMedia,
    BLink,
    BBadge,
    //BDropdown,
    //BDropdownItem,
    //BPagination,
    vSelect,
    //flatPickr,
    //'gallery': VueGallery
  },
  props: {
    /* modalFilter: {
      type: [String, null],
      default: null,
      },
      modalFilterOptions: {
      type: Array,
      required: true,
      }*/
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  mounted() {
    window.addEventListener("keydown", this.handleEvent);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleEvent);
  },
  async created() {
    let right = {};
    this.$store.state.menu.forEach((el) => {
      el.children.forEach((ch) => {
        right[ch.route] = {
          view: ch.view,
          add: ch.add,
          edit: ch.edit,
          delete: ch.delete,
          disableDate: ch.disableDate,
          receiveFee: ch.receiveFee,
        };
      });
    });
    // console.log(right);
    this.$store.commit("setRights", right);

    if (!this.$store.state.rights[this.$route.name]) {
      // console.log(this.$store.state.rights[this.$route.name]);
      this.$router.replace({
        name: "misc-not-authorized",
      });
    } else {
      this.rights = this.$store.state.rights[this.$route.name];
      // if (
      //   !this.receiveObj.rcvDate ||
      //   this.role.toLowerCase() !== "administrator" ||
      //   this.dateFor.includes(this.$store.state.clientID)
      // ) {
      //   this.receiveObj.rcvDate = new Date().toJSON();
      //   // console.log(this.receiveObj.rcvDate);
      //   this.$store.commit("setDate", this.receiveObj.rcvDate);
      //   this.challanObj.rcvDate = this.receiveObj.rcvDate;
      // }

      this.fetchTime();

      this.LoadData();
      this.loadAccounts();
      this.loadSubSetting();
      this.loadCOA();
      this.LoadSettingsAll();
      this.LoadUsers();
      await this.openSettings(false);
    }
  },
  computed: {
    ...mapGetters({ cmbDepartment: "cmbDepartment" }),
    ...mapGetters({ data: "data" }),

    PageChange() {
      console.log(this.currentPage);
      return this.LoadData();
    },
    filters: function () {
      return this.items.filter((pro) => {
        return pro.name.toLowerCase().match(this.searchQuery.toLowerCase());
      });
    },
    searchData: function () {
      return this.studentData.filter((pro) => {
        return pro.name.toLowerCase().match(this.filterData.toLowerCase());
      });
    },
    computedFields() {
      if (this.lateFee > 0 && this.timelyDiscount > 0) return this.fieldsBoth;
      else if (this.lateFee > 0) return this.fieldsLate;
      else if (this.timelyDiscount > 0) return this.fieldsDiscount;
      else return this.fields;
    },
    afterDis() {
      if (new Date(this.receiveObj.rcvDate) <= new Date(this.dueDateToShow)) {
        return this.total - this.discountToShow;
      } else return this.total - this.advanceToShow;
    },
  },
  data() {
    return {
      searchType: "grNo",
      reportDomain: this.$store.state.report.replace(/_/g, ""),
      iFamily: false,
      iMsg: false,
      iPrint: false,
      check: 1,
      fromShortcut: false,
      slipShortcut: false,
      swiperOptions: {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      rights: {},
      showAll: false,
      role: this.$store.state.userData.roles,
      contacterror: false,
      mydocloading: false,
      docsloading: "Upload",
      students: [],
      request: false,
      showDiscount: false,
      disObj: {
        feesID: 0,
        discountAmount: null,
        userID: this.$store.state.userData.userID,
        timelyDiscount: 0,
      },
      currentBalance: 0,
      netBalance: 0,

      instObj: {
        feeID: 0,
        amount: null,
        campusID: this.$store.state.userData.cId,
      },
      instCurrent: 0,
      visibility2: false,

      auto: false,
      fromChallan: false,
      challanObj: {
        challanNo: 0,
        amount: 0,
        rcvDate: this.$store.state.date,
        userID: this.$store.state.userData.userID,
        campusID: this.$store.state.userData.cId,
        feesIDs: [],
        accountID: this.$store.state.accountType,
      },

      receiveObj: {
        grNo: "",
        familyCode: 0,
        amount: 0,
        rcvDate: this.$store.state.date,
        userID: this.$store.state.userData.userID,
        campusID: this.$store.state.userData.cId,
        feesIDs: [],
        accountID: this.$store.state.accountType,
        receiveNote: "",
      },
      receiving: false,
      colorDep: [],
      visibility1: false,

      sidebarTitle: "Search",
      sidebarButton: "Save",
      visibility: false,
      dataLoading: false,
      gridLoading: false,
      studentLoading: false,
      saving: false,
      searchQuery: "",
      searchFather: "",
      searchStatus: "present",
      statusOptions: [
        { text: "All", value: "" },
        { text: "Present", value: "present" },
        { text: "Registered", value: "registered" },
        { text: "Left", value: "left" },
      ],
      currentPage: 1,
      totalRows: 0,

      errors: {
        status: false,
      },
      filterData: "",
      studentData: [],
      date: {
        range: "",
      },
      index: null,
      cover: "",

      fields: [
        // '-',
        { label: "gr", key: "gr" },
        { label: "Name", key: "name" },
        { label: "class", key: "class" },
        { label: "fee type", key: "feeType" },
        { label: "details", key: "monthYear" },
        { label: "balance", key: "balance" },
        { key: "actions", label: "actions" },
      ],
      fieldsLate: [
        { label: "gr", key: "gr" },
        { label: "Name", key: "name" },
        { label: "class", key: "class" },
        { label: "fee type", key: "feeType" },
        { label: "details", key: "monthYear" },
        { label: "late fee", key: "lateFee" },
        { label: "balance", key: "balance" },
        { key: "actions", label: "actions" },
      ],
      fieldsDiscount: [
        { label: "gr", key: "gr" },
        { label: "Name", key: "name" },
        { label: "class", key: "class" },
        { label: "fee type", key: "feeType" },
        { label: "details", key: "monthYear" },
        { label: "timely discount", key: "timelyDiscount" },
        { label: "balance", key: "balance" },
        { key: "actions", label: "actions" },
      ],
      fieldsBoth: [
        { label: "gr", key: "gr" },
        { label: "Name", key: "name" },
        { label: "class", key: "class" },
        { label: "fee type", key: "feeType" },
        { label: "details", key: "monthYear" },
        { label: "late fee", key: "lateFee" },
        { label: "timely discount", key: "timelyDiscount" },
        { label: "balance", key: "balance" },
        { key: "actions", label: "actions" },
      ],
      historyLoading: false,
      hisFields: [
        { label: "Slip No#", key: "billID" },
        { label: "fee type", key: "feeType" },
        { label: "Class", key: "class" },
        { label: "details", key: "monthYear" },
        { label: "total", key: "rcvd" },
        { label: "receiving date", key: "rcvdDate" },
        { label: "received by", key: "rcvdBy" },
        { key: "actions", label: "actions" },
      ],

      fields2: ["student"], //{ label: "picture", key: "picture" },
      items: [],
      history: [],
      selected: null,
      allSelect: false,
      filterOptions: [
        { text: "Most sold product", value: "mostSold" },
        { text: "Least sold product", value: "leastSold" },
        { text: "Most popular product", value: "mostPopular" },
        { text: "Least popular product", value: "leastPopular" },
        { text: "Most profitable product", value: "mostProfitable" },
      ],
      attachList: [],
      loadSetting: false,
      showSettings: false,
      settingOptions: ["Yes", "No"],
      settings: {
        id: 0,
        campusID: this.$store.state.userData.cId,
        issueDate: null,
        dueDate: null,
        expiryDate: null,
        lateFee: 0,
        timelyDiscount: 0,
        printSlip: false,
        sendSMS: false,
        instructions: "",
        bankName: "",
        accountNumber: "",
        printBy: "",
        titleOfAccount: "",
      },
      printOptions: [
        {
          text: "Student",
          value: "student",
        },
        {
          text: "Family",
          value: "family",
        },
      ],
      lateFee: 0,
      timelyDiscount: 0,

      myObj: {
        grNo: null,
        familyCode: null,
        challanNo: null,
        id: 0,
      },
      detailObj: {},
      avatarURL:
        "https://raw.githubusercontent.com/Ashwinvalento/cartoon-avatar/master/lib/images/female/110.png",
      createForm: false,
      allfeeType: [],
      accTypes: [],
      accountType: null,
      collection: {
        sID: 0,
        year: 0,
        userID: this.$store.state.userData.userID,
        feesTypeIDs: [],
        months: [],
        onBehalf: 0,
      },
      months: [
        {
          id: 1,
          name: "Jan",
        },
        {
          id: 2,
          name: "Feb",
        },
        {
          id: 3,
          name: "Mar",
        },
        {
          id: 4,
          name: "Apr",
        },
        {
          id: 5,
          name: "May",
        },
        {
          id: 6,
          name: "Jun",
        },
        {
          id: 7,
          name: "Jul",
        },
        {
          id: 8,
          name: "Aug",
        },
        {
          id: 9,
          name: "Sep",
        },
        {
          id: 10,
          name: "Oct",
        },
        {
          id: 11,
          name: "Nov",
        },
        {
          id: 12,
          name: "Dec",
        },
      ],
      years: [
        2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026,
        2027, 2028, 2029, 2030,
      ],
      sendChallan: false,
      total: null,
      balance: null,
      paid: null,
      discountToShow: 0,
      advanceToShow: 0,
      dueDateToShow: null,
      currentPos: 80,
      config: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
      },
      config2: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
        mode: "range",
      },
      fromGr: true,
      fromFamily: false,
      accountObj: {
        id: 0,
        campusID: this.$store.state.userData.cId,
        account: "",
        status: "active",
        accountID: 0,
      },
      accIcon: "",
      accountbar: false,
      addingAcc: false,
      savingacc: false,
      accFields: [
        { label: "Name", key: "account" },
        { key: "actions", label: "actions" },
      ],

      lastChanged: null,
      commitObj: {},
      visibility3: false,
      pSaving: false,
      unholding: false,
      historyObj: {
        dtFrom: null,
        dtTo: null,
      },
      hisOpen: false,
      hisPrinting: false,
      rangeDate: null,
      subObj: null,
      ledgObj: null,
      chDetails: null,
      schGr: null,
      showAllLate: false,
      showOnBehalf: false,
      toPrint: [],
      entryObj: null,
      coAccounts: [],
      dateFor: ["myskool_aims", "myskool_aims_c2"],
      noticeObj: null,
      cHeadObj: null,
      lateObj: null,
      backDtObj: null,
      withLate: false,
      importing: false,
      uploadSettings: {
        dateColumn: "TRANS.DATE",
        amountColumn: "AMOUNT",
        challanColumn: "Challan/C-ID",
        barcodeColumn: "BR.CODE",
      },
      savingSettings: false,
      showUpload: false,
      uploadObj: {},
      uploadedData: [],
      savingUpload: false,
      errorData: [],
      recLoading: false,
      receiptFields: [
        { label: "gr", key: "gr" },
        { label: "Name", key: "name" },
        { label: "class", key: "class" },
        { label: "fee type", key: "feeType" },
        { label: "details", key: "monthYear" },
        { label: "balance", key: "balance" },
        // { key: "actions", label: "actions" },
      ],
      receiptData: [],
      validating: false,
      validData: [],
      slipObj: {
        from: "",
        till: "",
      },
      printingSlip: false,
      slipNo: null,
      showLedg: false,
      orgList: [],
      visibility4: false,
      orgFields: [
        { label: "Name", key: "title" },
        { key: "actions", label: "actions" },
      ],
      otherObj: {
        organization: 0,
        bank: "",
        cheque: "",
      },
      orgObj: {},
      savingOrg: false,
      sending: false,
      allUsers: [],
      exempting: false,
      exObj: {},
    };
  },
  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({
      get: "get",
      post: "post",
      postObj: "postObj",
      put: "put",
      delete: "delete",
    }),

    fetchTime() {
      axios
        .get("https://worldtimeapi.org/api/timezone/Asia/Karachi")
        .then((response) => {
          // console.log(response.data);
          let date = response.data.datetime;
          this.receiveObj.rcvDate = date;
          this.$store.commit("setDate", this.receiveObj.rcvDate);
          this.challanObj.rcvDate = this.receiveObj.rcvDate;
          // console.log(this.receiveObj.rcvDate);
        })
        .catch((error) => {
          console.log("Error fetching time:", error);
          this.receiveObj.rcvDate = new Date().toJSON();
          this.$store.commit("setDate", this.receiveObj.rcvDate);
          this.challanObj.rcvDate = this.receiveObj.rcvDate;
          // console.log(this.receiveObj.rcvDate);
        });
    },
    checkLedger() {
      if (this.myObj.gr != null && this.myObj.gr != "" && this.myObj.doc) {
        let att = this.myObj.doc.split(",");
        let dd = att.find((el) =>
          el.toLowerCase().includes(`ledger_${this.myObj.gr}`)
        );
        // console.log(dd);
        this.showLedg = dd ? true : false;
      } else {
        // console.log("not");
        this.showLedg = false;
      }
    },
    openLedger() {
      let url = `https://cdn.cloudious.net/${this.ledgObj.value}/ledger_${this.myObj.gr}.pdf`;
      // Create a full-page iframe
      const iframe = document.createElement("iframe");
      iframe.style.position = "fixed";
      iframe.style.top = "0";
      iframe.style.left = "0";
      iframe.style.width = "100%";
      iframe.style.height = "100%";
      iframe.style.border = "none";
      iframe.style.zIndex = "1000000"; // Increased z-index
      iframe.src = url + "#toolbar=1&navpanes=1"; // Enable PDF toolbar and sidebar
      // Create a close button
      const closeButton = document.createElement("button");
      closeButton.innerHTML = "&times;";
      closeButton.style.position = "fixed";
      closeButton.style.top = "10px";
      closeButton.style.right = "10px";
      closeButton.style.fontSize = "24px";
      closeButton.style.background = "white";
      closeButton.style.border = "none";
      closeButton.style.borderRadius = "50%";
      closeButton.style.width = "30px";
      closeButton.style.height = "30px";
      closeButton.style.cursor = "pointer";
      closeButton.style.zIndex = "1000001"; // Ensure close button is above iframe
      // Add click event to close button
      closeButton.onclick = () => {
        document.body.removeChild(iframe);
        document.body.removeChild(closeButton);
      };
      // Add iframe and close button to the body
      document.body.appendChild(iframe);
      document.body.appendChild(closeButton);
    },

    async upload() {
      this.importing = true;
      const input = this.$refs.fileProfile.files[0];
      await readXlsxFile(input)
        .then((rows) => {
          // console.log(rows);
          const ind1 = rows.findIndex(
            (el) => el[0] == "TUITION" || el[0] == "tuition"
          );
          // console.log(ind1);
          const ind2 = rows.findIndex(
            (el) => el[0] == "TRANSPORT" || el[0] == "transport"
          );
          // console.log(ind2);
          let dataset1 = rows.slice(ind1, ind2);
          let dataset2 = rows.slice(ind2);
          // console.log(dataset1, dataset2);
          const data1 = dataset1.slice(2).reduce((result, el) => {
            if (el[1] && el[2] && el[3] && el[4]) {
              result.push({
                date: el[1],
                amount: el[2],
                challan: el[3],
                barcode: el[4],
              });
            }
            return result;
          }, []);
          console.log(data1);
          const data2 = dataset2.slice(2).reduce((result, el) => {
            if (el[1] && el[2] && el[3] && el[4]) {
              result.push({
                date: el[1],
                amount: el[2],
                challan: el[3],
                barcode: el[4],
              });
            }
            return result;
          }, []);
          console.log(data2);
          this.importing = false;
          if (data1.length == 0 && data2.length == 0) {
            this.$bvToast.toast("No record found!", {
              title: "Error!",
              variant: "danger",
              toaster: "b-toaster-top-center",
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.importing = false;
        });

      // const schema = {
      //   [this.uploadSettings.dateColumn]: {
      //     prop: "date",
      //     type: String,
      //   },
      //   [this.uploadSettings.amountColumn]: {
      //     prop: "amount",
      //     type: Number,
      //   },
      //   [this.uploadSettings.challanColumn]: {
      //     prop: "challan",
      //     type: Number,
      //   },
      //   [this.uploadSettings.barcodeColumn]: {
      //     prop: "barcode",
      //     type: Number,
      //   },
      // };

      // const input = this.$refs.fileProfile.files[0];
      // await readXlsxFile(input, { schema })
      //   .then((rows) => {
      //     // console.log(rows);
      //     let res = rows.rows;
      //     let formatted = res.filter((el) => el.challan || el.barcode);
      //     console.log(formatted);
      //     this.importing = false;
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //     this.importing = false;
      //   });
    },

    async uploadOther() {
      this.importing = true;
      const input = this.$refs.fileProfile.files[0];
      const schema = {
        "Payment Date": {
          prop: "date",
          // type: (value) => {
          //   return new Date(value).toJSON();
          // },
          type: (value) => {
            console.log(value);
            const parts = value.split("/");
            const day = parts[0];
            const month = parts[1];
            const year = parts[2];

            const formattedDate = `${year}-${month.padStart(
              2,
              "0"
            )}-${day.padStart(2, "0")}`;

            return formattedDate;
          },
        },
        "Total Fee Paid": {
          prop: "amount",
          // type: Number,
          type: (value) => {
            let res = value
              .toString()
              .toLowerCase()
              .replace(/\b(rs|pkr|rs.)\b/g, "")
              .trim();
            return parseInt(res);
          },
        },
        "C-ID": {
          prop: "sID",
          type: Number,
        },
      };

      await readXlsxFile(input, { schema })
        .then((rows) => {
          // console.log(rows);
          this.uploadedData = rows.rows.filter(
            (el) => el.date && el.amount >= 0 && el.sID
          );
          console.log(this.uploadedData);
          this.importing = false;
          if (this.uploadedData.length == 0) {
            this.$bvToast.toast("No record found!", {
              title: "Error!",
              variant: "danger",
              toaster: "b-toaster-top-center",
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.importing = false;
        });
    },

    async uploadNew() {
      this.importing = true;
      const input = this.$refs.fileProfile.files[0];
      const schema = {
        Date: {
          prop: "date",
          type: Date,
        },
        Amount: {
          prop: "amount",
          type: Number,
          // type: (value) => {
          //   let res = value
          //     .toString()
          //     .toLowerCase()
          //     .replace(/\b(rs|pkr|rs.)\b/g, "")
          //     .trim();
          //   return parseInt(res);
          // },
        },
        "C-ID": {
          prop: "sID",
          type: String,
        },
      };

      await readXlsxFile(input, { schema })
        .then((rows) => {
          // console.log(rows);
          this.uploadedData = rows.rows.filter(
            (el) => el.date && el.amount > 0 && el.sID
          );
          console.log(this.uploadedData);

          if (this.uploadedData.length == 0) {
            this.$bvToast.toast("No record found!", {
              title: "Error!",
              variant: "danger",
              toaster: "b-toaster-top-center",
            });
            this.importing = false;
            this.errorData = [];
          } else this.verifyData();
        })
        .catch((err) => {
          console.log(err);
          this.importing = false;
        });
    },

    async verifyData() {
      if (this.uploadObj.accountID == 0) {
        this.$bvToast.toast("Please select collection account.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
        this.importing = false;
      } else {
        var status = await this.post({
          url:
            this.$store.state.domain +
            "Fees/PostBankFeeVerify?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&accID=" +
            this.uploadObj.accountID +
            "&isTransport=" +
            this.uploadObj.isTransport +
            "&userID=" +
            this.$store.state.userData.userID,
          body: this.uploadedData,
          context: this,
          token: this.$store.state.userData.token,
          upload: true,
        });
        if (status == true) {
          this.errorData = [];
          this.saveUpload();
        } else if (Array.isArray(status)) {
          this.errorData = status;
          const expData = this.errorData.map((item) => ({
            Date: item.date,
            Amount: item.amount,
            "C-ID": item.sID,
            Note: item.note,
          }));
          const worksheet = XLSX.utils.json_to_sheet(expData);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
          XLSX.writeFile(workbook, "Errors.xlsx");
          this.importing = false;
        }
      }
    },
    async saveUpload() {
      // console.log(this.uploadedData);
      // this.savingUpload = true;
      var status = await this.post({
        url:
          this.$store.state.domain +
          "Fees/PostBankFee?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&accID=" +
          this.uploadObj.accountID +
          "&isTransport=" +
          this.uploadObj.isTransport +
          "&userID=" +
          this.$store.state.userData.userID,
        body: this.uploadedData,
        message: "Data saved successfully",
        context: this,
        token: this.$store.state.userData.token,
      });
      // this.savingUpload = false;
      this.importing = false;
      if (status) {
        this.showUpload = false;
        this.uploadedData = [];
      }
      // if (this.uploadObj.accountID == 0) {
      //   this.$bvToast.toast("Please select collection account.", {
      //     title: "Error!",
      //     variant: "danger",
      //     toaster: "b-toaster-top-center",
      //   });
      // } else if (this.uploadedData.length == 0) {
      //   this.$bvToast.toast("No record found!", {
      //     title: "Error!",
      //     variant: "danger",
      //     toaster: "b-toaster-top-center",
      //   });
      // } else {

      // }
    },

    async uploadValidate() {
      this.validating = true;
      const input = this.$refs.validProfile.files[0];
      const schema = {
        Date: {
          prop: "date",
          type: Date,
        },
        Amount: {
          prop: "amount",
          type: Number,
        },
        "C-ID": {
          prop: "sID",
          type: String,
        },
      };

      await readXlsxFile(input, { schema })
        .then((rows) => {
          // console.log(rows);
          this.validData = rows.rows.filter(
            (el) => el.date && el.amount > 0 && el.sID
          );
          console.log(this.validData);

          if (this.validData.length == 0) {
            this.$bvToast.toast("No record found!", {
              title: "Error!",
              variant: "danger",
              toaster: "b-toaster-top-center",
            });
            this.validating = false;
          } else this.checkFile();
        })
        .catch((err) => {
          console.log(err);
          this.validating = false;
        });
    },

    async checkFile() {
      if (this.uploadObj.accountID == 0) {
        this.$bvToast.toast("Please select collection account.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
        this.validating = false;
      } else {
        var status = await this.post({
          url:
            this.$store.state.domain +
            "Fees/ValidateFile?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&accID=" +
            this.uploadObj.accountID +
            "&isTransport=" +
            this.uploadObj.isTransport +
            "&userID=" +
            this.$store.state.userData.userID,
          body: this.validData,
          context: this,
          token: this.$store.state.userData.token,
          validation: true,
        });
        if (Array.isArray(status)) {
          const expData = status.map((item) => ({
            Date: item.date,
            Amount: item.amount,
            "C-ID": item.sID,
            Note: item.note,
          }));
          const worksheet = XLSX.utils.json_to_sheet(expData);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
          XLSX.writeFile(workbook, "Validate.xlsx");
          this.validating = false;
        }
        this.validating = false;
      }
    },

    async LoadOrg() {
      var obj = {
        url:
          this.$store.state.domain +
          "DropDownList/LoadData?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&category=org",
        token: this.$store.state.userData.token,
      };
      this.orgList = await this.get(obj);
      // console.log(this.orgList);
    },
    openOrg() {
      this.orgObj = {
        id: 0,
        title: "",
        typee: "org",
        campusID: this.$store.state.userData.cId,
      };
      this.visibility4 = true;
      var elem = this.$refs["org_name"];
      elem.state = undefined;
    },
    checkOrg() {
      var elem = this.$refs["org_name"];
      if (this.orgObj.title == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async saveOrg() {
      if (this.checkOrg() == true) {
        this.savingOrg = true;
        if (this.orgObj.id == 0) {
          var status = await this.post({
            url:
              this.$store.state.domain +
              "DropDownList?db=" +
              this.$store.state.userData.db,
            body: this.orgObj,
            message: "Organization added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
        } else {
          var status = await this.put({
            url:
              this.$store.state.domain +
              "DropDownList/" +
              this.orgObj.id +
              "?db=" +
              this.$store.state.userData.db,
            message: "Organization updated successfully.",
            context: this,
            body: this.orgObj,
            token: this.$store.state.userData.token,
          });
        }
        if (status) {
          this.LoadOrg();
          this.orgObj = {
            id: 0,
            title: "",
            typee: "org",
            campusID: this.$store.state.userData.cId,
          };
          var elem = this.$refs["org_name"];
          elem.state = undefined;
        }
        this.savingOrg = false;
      }
    },
    async editOrg(acc) {
      this.orgObj = { ...acc };
      var elem = this.$refs["org_name"];
      elem.state = undefined;
    },
    async deleteOrg(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "DropDownList/" +
            id +
            "?db=" +
            this.$store.state.userData.db,
          message: "Organization deleted successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadOrg();
      }
    },

    async loadCOA() {
      var obj = {
        url:
          this.$store.state.domain +
          "Accounts/LoadData?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.coAccounts = await this.get(obj);
      // console.log(this.coAccounts);
    },
    setCOA(el) {
      if (el == null || el == "") this.accountObj.accountID = 0;
    },

    async loadSubSetting() {
      let res = await this.post({
        url:
          this.$store.state.domain +
          "Settings/getSettings?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        body: [
          "fee_sub_categories",
          "fee_notice",
          "double_entry",
          "receive_late",
          "prevent_back_date",
          "challan_with_header",
        ],
        context: this,
        token: this.$store.state.userData.token,
        subjects: true,
      });

      this.subObj = res.find((el) => el.key == "fee_sub_categories");
      this.entryObj = res.find(
        (el) => el.key.replace(/\r\n/g, "") == "double_entry"
      );
      this.noticeObj = res.find((el) => el.key == "fee_notice");
      this.cHeadObj = res.find((el) => el.key == "challan_with_header");
      this.lateObj = res.find((el) => el.key == "receive_late");
      this.backDtObj = res.find((el) => el.key == "prevent_back_date");
      // console.log(this.backDtObj);

      if (this.backDtObj && this.backDtObj.valueBool) {
        this.fetchTime();
        // this.receiveObj.rcvDate = new Date().toJSON();
        // this.$store.commit("setDate", this.receiveObj.rcvDate);
        // this.challanObj.rcvDate = this.receiveObj.rcvDate;
      }
    },
    async LoadSettingsAll() {
      let res = await this.post({
        url:
          this.$store.state.domain +
          "Settings/getSettingsAll?db=" +
          this.$store.state.userData.db,
        body: ["std_ledger", "cheque_details", "local_gr"],
        context: this,
        token: this.$store.state.userData.token,
        subjects: true,
      });
      this.ledgObj = res.find((el) => el.key == "std_ledger");
      this.chDetails = res.find((el) => el.key == "cheque_details");
      // this.schGr = res.find((el) => el.key == "local_gr");
      // console.log(this.schGr);
      if (this.chDetails && this.chDetails.valueBool) this.LoadOrg();
    },
    async LoadUsers() {
      this.allUsers = await this.get({
        url:
          this.$store.state.domain +
          "Users/LoadUsersBasic?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      });
    },
    getUser(id) {
      let obj = this.allUsers.find((el) => el.id == id);
      return obj ? obj.username : "";
    },
    setAll() {
      if (this.toPrint.includes(0)) {
        this.toPrint = this.allfeeType.reduce((acc, c) => {
          acc.push(c.id);
          return acc;
        }, []);
      }
    },

    openPrint() {
      this.hisOpen = true;
    },
    setHisDate() {
      let d = this.rangeDate.split(" to ");
      this.historyObj.dtFrom = d[0];
      this.historyObj.dtTo = d[1];
      // console.log(this.historyObj);
    },
    printHistory() {
      if (!this.myObj.gr && !this.myObj.familyCode) {
        this.$bvToast.toast("Please select the student first", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else if (!this.historyObj.dtFrom || !this.historyObj.dtTo) {
        this.$bvToast.toast("Please select valid date", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        this.hisPrinting = true;

        let fcode = 0;
        let gr = 0;
        if (this.lastChanged === "family") {
          fcode = this.myObj.familyCode;
        } else {
          gr = this.myObj.id;
        }
        var url =
          `https://${this.reportDomain}.myskool.app/Fee/ShowHistory` +
          "?dtFrom=" +
          this.historyObj.dtFrom +
          "&dtTo=" +
          this.historyObj.dtTo +
          "&dbb=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&sID=" +
          gr +
          "&family=" +
          fcode;

        window.open(url, "_blank");
        this.hisPrinting = false;
      }
    },
    openCom() {
      this.commitObj = {
        date: null,
        reason: "",
        feeIDs: this.receiveObj.feesIDs,
      };
      this.visibility3 = true;
    },
    CheckComDate() {
      var elem = this.$refs["commit_date"];
      if (this.commitObj.date == "" || this.commitObj.date == null) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async saveCommitment() {
      if (this.CheckComDate() == true) {
        this.pSaving = true;
        // console.log(this.commitObj);
        var status = await this.post({
          url:
            this.$store.state.domain +
            "Fees/HoldFeeSelected?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          body: this.commitObj,
          message: "Fees holded successfully",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) {
          this.visibility3 = false;
          this.loadGrid();
        }
        this.pSaving = false;
      }
    },
    async unHoldFee() {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "Do you want to unhold the selected fee?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Unhold it!",
      });

      if (result.isConfirmed) {
        this.unholding = true;
        let obj = {
          feeIDs: this.receiveObj.feesIDs,
          campusID: this.$store.state.userData.cId,
        };
        // console.log(obj);
        var status = await this.post({
          url:
            this.$store.state.domain +
            "Fees/UnholdFeeSelected?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          body: obj,
          message: "Fees unholded successfully",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.unholding = false;
        if (status) {
          this.loadGrid();
        }
      }
    },

    handleEvent(event) {
      if (this.rights.add || this.rights.receiveFee) {
        if (event.key == "Escape") {
          this.closeForm();
        } else if (event.ctrlKey && event.shiftKey && event.keyCode === 83) {
          if (this.check == 1) {
            this.check = 0;
            this.fromShortcut = true;
            this.slipShortcut = true;
            // console.log(this.check, this.fromShortcut, this.slipShortcut);
            this.receiveFee("normal");
          }
        } else if (event.ctrlKey && event.keyCode === 83) {
          event.preventDefault();
          if (this.check == 1) {
            this.check = 0;
            // console.log(this.check);
            this.fromShortcut = true;
            this.slipShortcut = false;
            this.receiveFee("normal");
          }
        }
      }
    },
    PrintChallan() {
      var contr = "Challan";
      if (this.myObj.status.toLowerCase() == "present") {
        contr = "Challan";
      } else {
        contr = "ChallanLeft";
      }
      let url =
        `https://${this.reportDomain}.myskool.app/Fee/` +
        contr +
        "?dbb=" +
        this.$store.state.userData.db +
        "&sID=" +
        this.myObj.id +
        "&cID=" +
        this.$store.state.userData.cId +
        "&fID=" +
        this.receiveObj.feesIDs;
      window.open(url, "_blank");
    },
    async checkPrint(text) {
      if (this.myObj.id == 0) {
        this.$bvToast.toast("Please select the student first.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        await this.openSettings();

        if (
          this.settings.printBy != null &&
          this.settings.printBy.toLowerCase() == "student" &&
          this.iFamily
        ) {
          Swal.fire({
            // title: "",
            html: `<div class='text-left'>
              <p class='mb-50'>You have Student wise print option enabled.</p>
              <p class='mb-50'>Please enter by Gr# to proceed.</p>
            </div>`,
            icon: "warning",
            showCloseButton: false,
            showCancelButton: false,
            confirmButtonColor: "#d33",
            confirmButtonText: "Ok",
          });
        } else if (
          this.settings.printBy != null &&
          this.settings.printBy.toLowerCase() == "family" &&
          !this.iFamily
        ) {
          Swal.fire({
            // title: "",
            html: `<div class='text-left'>
              <p class='mb-50'>You have Family wise print option enabled.</p>
              <p class='mb-50'>Please enter by Family code to proceed.</p>
            </div>`,
            icon: "warning",
            showCloseButton: false,
            showCancelButton: false,
            confirmButtonColor: "#d33",
            confirmButtonText: "Ok",
          });
        } else {
          let result = await Swal.fire({
            // title: "",
            html: `<div class='text-left'><p class='mb-50'>Your fee details are:</p>
             <b>Issue Date: </b>  ${new Date(
               this.settings.issueDate
             ).toLocaleDateString("en-UK", {
               year: "numeric",
               day: "numeric",
               month: "short",
             })}<br/>
            <b>Due Date: </b> ${new Date(
              this.settings.dueDate
            ).toLocaleDateString("en-UK", {
              year: "numeric",
              day: "numeric",
              month: "short",
            })}<br/>
            <b>Expiry Date: </b> ${new Date(
              this.settings.expiryDate
            ).toLocaleDateString("en-UK", {
              year: "numeric",
              day: "numeric",
              month: "short",
            })}<br/>
            <p class='mt-1'>Are you sure you want to proceed?</p>
            </div>`,
            icon: "warning",
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Yes",
            cancelButtonText: "Edit Settings",
          });

          // console.log(result);
          if (result.isConfirmed) {
            if (text == "print") this.print();
            else this.SendChallan();
          } else if (result.dismiss == "cancel") {
            this.openSettings(true);
          }
        }
      }
    },
    async print() {
      // await this.openSettings();
      var ffc = this.myObj.familyCode;
      var controller = "GenerateChallan";

      var contr = "Challan";
      // console.log(this.myObj.status.toLowerCase());
      if (this.myObj.status.toLowerCase() == "present") {
        contr = "Challan";
      } else {
        contr = "ChallanLeft";
      }

      if (
        this.settings.printBy != null &&
        this.settings.printBy.toLowerCase() == "student"
      ) {
        if (this.myObj.status.toLowerCase() == "present") {
          controller = "GenerateChallanStudent";
        } else {
          controller = "GenerateChallanStudentLeft";
        }

        ffc = -1;
      }

      this.iPrint = true;
      try {
        var pObj = {
          fCode: this.myObj.familyCode,
          sID: this.myObj.id,
          cID: [],
          secID: [],
          feeTypeID: [],
          months: [],
          year: 0,
          familyWise: true,
          userID: this.$store.state.userData.userID,
          feeIDs: this.receiveObj.feesIDs,
        };

        if (pObj.familyCode == null) pObj.familyCode = 0;
        if (pObj.sID == null) pObj.sID = 0;

        if (this.iFamily) pObj.sID = 0;

        var objData = await this.postObj({
          url:
            this.$store.state.domain +
            "feesChallan/" +
            controller +
            "?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&exp=false",
          body: pObj,
          message: null,
          context: this,
          token: this.$store.state.userData.token,
        });
        console.log(objData);

        let header = this.cHeadObj ? this.cHeadObj.valueBool : false;

        if (objData.length > 0) {
          let url =
            `https://${this.reportDomain}.myskool.app/Fee/` +
            contr +
            "?dbb=" +
            this.$store.state.userData.db +
            "&sID=" +
            this.myObj.id +
            "&cID=" +
            this.$store.state.userData.cId +
            "&cls=" +
            "" +
            "&sec=" +
            "" +
            "&feeType=" +
            "" +
            "&fCode=" +
            ffc +
            "&fcID=" +
            objData +
            "&duedate=" +
            this.settings.dueDate +
            "&header=" +
            header;

          if (!this.iFamily) {
            url =
              `https://${this.reportDomain}.myskool.app/Fee/` +
              contr +
              "?dbb=" +
              this.$store.state.userData.db +
              "&sID=" +
              this.myObj.id +
              "&cID=" +
              this.$store.state.userData.cId +
              "&cls=" +
              "" +
              "&sec=" +
              "" +
              "&feeType=" +
              "" +
              "&fCode=" +
              ffc +
              "&fcID=" +
              objData +
              "&duedate=" +
              this.settings.dueDate +
              "&header=" +
              header;
          }

          let urlSelectedMonth = url + "&month=" + this.selectedMonths;

          // console.log(url);
          window.open(url, "_blank");
        } else {
          alert("Nothing to print.");
        }
      } catch (error) {
      } finally {
        this.iPrint = false;
      }
    },
    async SendChallan() {
      // await this.openSettings();
      var ffc = this.myObj.familyCode;
      var controller = "GenerateChallan";
      if (
        this.settings.printBy != null &&
        this.settings.printBy.toLowerCase() == "student"
      ) {
        if (this.myObj.status.toLowerCase() == "present") {
          controller = "GenerateChallanStudent";
        } else {
          controller = "GenerateChallanStudentLeft";
        }
        ffc = -1;
      }

      this.iMsg = true;
      try {
        var pObj = {
          fCode: this.myObj.familyCode,
          sID: this.myObj.id,
          cID: [],
          secID: [],
          feeTypeID: [],
          months: [],
          year: 0,
          familyWise: true,
          userID: this.$store.state.userData.userID,
          feeIDs: this.receiveObj.feesIDs,
        };

        if (pObj.familyCode == null) pObj.familyCode = 0;
        if (pObj.sID == null) pObj.sID = 0;

        if (this.iFamily) pObj.sID = 0;

        var objData = await this.postObj({
          url:
            this.$store.state.domain +
            "feesChallan/" +
            controller +
            "?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&exp=true",
          body: pObj,
          message: "Sent on WhatsApp",
          context: this,
          token: this.$store.state.userData.token,
        });
      } catch (error) {
      } finally {
        this.iMsg = false;
      }
    },
    async sendSlip(id) {
      console.log(id);
      // this.sending = true;
      // let myitem = await this.get({
      //   url:
      //     this.$store.state.domain +
      //     "Fees/SendSlip?db=" +
      //     this.$store.state.userData.db +
      //     "&cID=" +
      //     this.$store.state.userData.cId +
      //     "&billID=" +
      //     id,
      //   message: "Slip send successfully!",
      //   token: this.$store.state.userData.token,
      // });
      // this.sending = false;
    },
    printSlip(id, rem) {
      // console.log(id, rem);
      let url =
        `https://${this.reportDomain}.myskool.app/Fee/Slip?dbb=` +
        this.$store.state.userData.db +
        "&bID=" +
        id +
        "&cID=" +
        this.$store.state.userData.cId +
        "&rem=" +
        rem;
      window.open(url, "_blank");
    },
    slidechange() {
      // console.log(this.$refs.myswiper.$swiper.activeIndex);
      this.myObj = this.students[this.$refs.myswiper.$swiper.activeIndex];

      this.collection.sID = this.myObj.id;
      // console.log(this.myObj, this.collection);
      this.checkLedger();
    },
    setLastChanged(name) {
      this.lastChanged = name;
    },
    getReport() {
      if (!this.myObj.gr && !this.myObj.familyCode) {
        this.$bvToast.toast("Please select the student first", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        let fcode = 0;
        let gr = 0;
        if (this.lastChanged === "family") {
          fcode = this.myObj.familyCode;
        } else {
          gr = this.myObj.id;
        }
        var url =
          `https://${this.reportDomain}.myskool.app/Fee/PrintOutstanding?dbb=` +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&sID=" +
          gr +
          "&family=" +
          fcode;

        window.open(url, "_blank");
      }
    },
    show() {
      // console.log(this.lastChanged);
      if (this.lastChanged === "gr") {
        this.byGR();
      } else if (this.lastChanged === "family") {
        this.byFamily();
      } else if (this.lastChanged === "challan") {
        this.byChallan();
      } else {
        this.$bvToast.toast("Please select the student first.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      }
    },

    byGR() {
      this.iFamily = false;
      if (this.myObj.gr == null || this.myObj.gr == "") {
        this.$bvToast.toast("Please select the student first.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        this.loadGrid(this.myObj.gr);
      }
    },
    byFamily() {
      if (this.myObj.familyCode == null || this.myObj.familyCode == "") {
        this.$bvToast.toast("Please select the student first.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        this.loadByFamily();
      }
    },
    async showSidebar() {
      this.visibility = true;
    },
    hideSideBar() {
      this.visibility = false;
    },
    openInst(item) {
      // console.log(item);
      this.instObj = {
        feeID: item.fees.id,
        amount: null,
        campusID: this.$store.state.userData.cId,
      };
      this.instCurrent = item.fees.balance;
      this.$refs["instAmount"].state = undefined;
      this.visibility2 = true;
    },
    checkInstallment() {
      var elem = this.$refs["instAmount"];
      let x = parseInt(this.instObj.amount);
      if (isNaN(x) || x <= 0 || x >= this.instCurrent) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async saveInstallment() {
      if (this.checkInstallment() == true) {
        this.instObj.amount = parseInt(this.instObj.amount);
        // console.log(this.instObj);
        this.request = true;
        var status = await this.post({
          url:
            this.$store.state.domain +
            "Fees/BreakFee?db=" +
            this.$store.state.userData.db,
          body: this.instObj,
          message: "Installment added successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.request = false;
        if (status) {
          this.visibility2 = false;
          this.loadGrid();
        }
      }
    },
    openDiscount(item) {
      // console.log(item);
      this.disObj = {
        feesID: item.fees.id,
        discountAmount: item.fees.discount,
        userID: this.$store.state.userData.userID,
        timelyDiscount:
          this.settings.timelyDiscount > 0 ? item.fees.timelyDiscount : 0,
        discountNote: item.fees.discountNote,
      };
      this.currentBalance = item.fees.actualFee;
      this.netBalance =
        item.fees.actualFee - item.fees.discount - this.disObj.timelyDiscount;
      this.$refs["discount"].state = undefined;
      this.showDiscount = true;
    },
    hideDiscount() {
      this.showDiscount = false;
    },
    validateDiscount() {
      var elem = this.$refs["discount"];
      if (this.checkDiscount() == true) {
        this.netBalance =
          this.currentBalance -
          this.disObj.discountAmount -
          this.disObj.timelyDiscount;
        elem.state = undefined;
      } else {
        this.netBalance = this.currentBalance;
      }
    },
    validateTimely() {
      var elem = this.$refs["timely"];
      if (
        this.disObj.timelyDiscount < 0 ||
        this.disObj.timelyDiscount == null ||
        this.disObj.timelyDiscount == "" ||
        parseInt(this.disObj.timelyDiscount) +
          parseInt(this.disObj.discountAmount) >
          this.currentBalance
      ) {
        elem.state = false;
        this.netBalance = this.currentBalance;
      } else {
        this.netBalance =
          this.currentBalance -
          this.disObj.discountAmount -
          this.disObj.timelyDiscount;
        elem.state = true;
      }
    },
    async AddDiscount() {
      if (this.checkDiscount() == false) {
        return this.$bvToast.toast("Please enter the discount amount.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-right",
        });
      } else {
        this.disObj.discountAmount = parseInt(this.disObj.discountAmount);
        this.disObj.timelyDiscount = parseInt(this.disObj.timelyDiscount);
        // console.log(this.disObj);
        this.request = true;
        var status = await this.post({
          url:
            this.$store.state.domain +
            "Fees/AddDiscount?db=" +
            this.$store.state.userData.db,
          body: this.disObj,
          message: "Discount added successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) {
          this.showDiscount = false;
          this.loadGrid();
        }
        this.request = false;
      }
    },
    checkDiscount() {
      // var reg = /^\d+$/;
      // console.log(reg.test(this.disObj.discountAmount));
      var elem = this.$refs["discount"];
      if (
        // this.disObj.discountAmount <= 0 ||
        this.disObj.discountAmount == null ||
        this.disObj.discountAmount == "" ||
        parseInt(this.disObj.discountAmount) +
          parseInt(this.disObj.timelyDiscount) >
          this.currentBalance
      ) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    openExempt(id) {
      this.exObj = {
        feeID: id,
        reason: "",
      };
      this.$bvModal.show("modal-exempt");
    },
    checkExReason() {
      var elem = this.$refs["ex_reason"];
      if (this.exObj.reason == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async ExemptWithNote() {
      if (this.checkExReason() == true) {
        let result = await Swal.fire({
          title: "Are you sure?",
          text: "Do you want to exempt this fee?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Yes!",
        });

        if (result.isConfirmed) {
          // console.log(this.exObj);
          this.exempting = true;
          var status = await this.post({
            url:
              this.$store.state.domain +
              "Fees/ExemptFee?db=" +
              this.$store.state.userData.db +
              "&cID=" +
              this.$store.state.userData.cId +
              "&feeID=" +
              this.exObj.feeID +
              "&reason=" +
              this.exObj.reason +
              "&userID=" +
              this.$store.state.userData.userID,
            message: "Fee exempt successfully.",
            context: this,
            body: null,
            token: this.$store.state.userData.token,
          });
          this.exempting = false;
          if (status) {
            this.$bvModal.hide("modal-exempt");
            this.loadGrid();
          }
        }
      }
    },
    async Exempt(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "Do you want to exempt this fee?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes!",
      });

      if (result.isConfirmed) {
        var status = await this.post({
          url:
            this.$store.state.domain +
            "Fees/ExemptFee?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&feeID=" +
            id,
          message: "Fee exempt successfully.",
          context: this,
          body: null,
          token: this.$store.state.userData.token,
        });
        if (status) this.loadGrid();
      }
    },
    async UnExempt(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "Do you want to exempt this fee?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes!",
      });

      if (result.isConfirmed) {
        var status = await this.post({
          url:
            this.$store.state.domain +
            "Fees/UnExemptFee?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&feeID=" +
            id,
          message: "Fee unexempt successfully.",
          context: this,
          body: null,
          token: this.$store.state.userData.token,
        });
        if (status) this.loadGrid();
      }
    },
    async deleteFee(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.put({
          url:
            this.$store.state.domain +
            "Fees/DeleteFee?" +
            "db=" +
            this.$store.state.userData.db +
            "&id=" +
            id +
            "&userID=" +
            this.$store.state.userData.userID,
          message: "Fee deleted successfully.",
          context: this,
          body: null,
          token: this.$store.state.userData.token,
        });
        if (status) this.loadGrid();
      }
    },
    async CancelFee(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Cancel it!",
      });

      if (result.isConfirmed) {
        var status = await this.put({
          url:
            this.$store.state.domain +
            "Fees/CancelSlip?" +
            "db=" +
            this.$store.state.userData.db +
            "&campusID=" +
            this.$store.state.userData.cId +
            "&billID=" +
            id +
            "&userID=" +
            this.$store.state.userData.userID,
          message: "Fee cancelled successfully.",
          context: this,
          body: null,
          token: this.$store.state.userData.token,
        });
        if (status) this.loadHistoryGrid();
      }
    },
    async loadSearch() {
      //  this.items =[];
      if (this.searchQuery.trim() !== "") {
        this.studentLoading = true;
        let myitem = await this.get({
          url:
            this.$store.state.domain +
            "Students/FeeSearch?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&name=" +
            this.searchQuery +
            "&fatherName=" +
            this.searchFather +
            "&status=" +
            this.searchStatus,
          token: this.$store.state.userData.token,
        });
        this.studentData = myitem;
        // console.log("stud", this.studentData);
        this.studentLoading = false;
      }
    },
    async loadbyFather() {
      //  this.items =[];
      if (this.searchFather.trim() !== "") {
        // console.log(this.searchFather);
        this.studentLoading = true;
        let myitem = await this.get({
          url:
            this.$store.state.domain +
            "Students/FeeSearch?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&name=" +
            this.searchQuery +
            "&fatherName=" +
            this.searchFather +
            "&status=" +
            this.searchStatus,
          token: this.$store.state.userData.token,
        });
        this.studentData = myitem;
        // console.log("stud", this.studentData);
        this.studentLoading = false;
      }
    },
    async byChallan() {
      if (this.myObj.challanNo == null || this.myObj.challanNo == "") {
        this.$bvToast.toast("Please enter the Challan No. first.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        this.gridLoading = true;
        this.fromGr = true;
        this.history = [];
        this.fromChallan = true;
        this.challanObj.feesIDs = [];
        this.receiveObj.feesIDs = [];
        this.$refs.selectableTable.clearSelected();

        let res = await this.get({
          url:
            this.$store.state.domain +
            "Fees/GetByChallan?db=" +
            this.$store.state.userData.db +
            "&campusID=" +
            this.$store.state.userData.cId +
            "&challanNo=" +
            this.myObj.challanNo,
          token: this.$store.state.userData.token,
        });
        // console.log("res", res);

        this.items = res.dues;
        this.total = res.duesTotal;
        this.paid = res.duesTotal;
        var elem = this.$refs["paid"];
        elem.state = undefined;
        this.balance = 0;

        this.myObj = {
          gr: null,
          familyCode: null,
          challanNo: this.myObj.challanNo,
          id: 0,
        };

        this.gridLoading = false;

        // if (this.items.length > 0 && this.auto) {
        //   this.receiveFee("challan");
        // }
      }
    },
    // receiveChallan(){

    // },
    async loadGrid(id) {
      this.fromChallan = false;
      this.challanObj.feesIDs = [];
      this.receiveObj.feesIDs = [];

      if (this.$refs.selectableTable) {
        this.$refs.selectableTable.clearSelected();
      }

      this.visibility = false;
      this.allSelect = false;
      this.gridLoading = true;
      this.historyLoading = true;

      var obj = {
        url:
          this.$store.state.domain +
          "Fees/GetStudentFeesDataExemption?db=" +
          this.$store.state.userData.db +
          "&campusID=" +
          this.$store.state.userData.cId +
          "&grNo=" +
          this.myObj.gr +
          "&familyCode=0&sID=" +
          `${id ? id : this.myObj.id}`,
        token: this.$store.state.userData.token,
        // +
        // this.myObj.familyCode
      };
      let res = await this.get(obj);
      if (this.receiving) {
        this.receiving = false;
        if (this.fromShortcut && this.slipShortcut) {
          this.printSlip(this.slipNo, res[0].duesTotal);
        } else if (this.settings.printSlip) {
          //Print Slip
          this.printSlip(this.slipNo, res[0].duesTotal);
        }
        this.fromShortcut = false;
        this.slipShortcut = false;
        this.check = 1;
      }
      // console.log("res", res);
      if (res === "No record found") {
        this.gridLoading = false;
        this.historyLoading = false;
        this.items = [];
        this.history = [];
        this.students = [];
        this.fromGr = true;

        this.myObj = {
          gr: this.myObj.gr,
          familyCode: null,
          challanNo: null,
          id: 0,
        };
        this.total = null;
        this.balance = null;
        this.paid = null;

        this.discountToShow = 0;
        this.advanceToShow = 0;
        this.dueDateToShow = null;

        this.receiveObj.feesIDs = [];
      } else {
        this.fromGr = true;
        this.items = res[0].dues;
        this.history = res[0].history;
        this.myObj = res[0].std[0];
        // this.students = res[0].std;

        this.collection = {
          sID: this.myObj.id,
          year: new Date().getFullYear(),
          userID: this.$store.state.userData.userID,
          feesTypeIDs: [],
          months: [],
          onBehalf: 0,
        };
        this.receiveObj.feesIDs = [];
        this.receiveObj.familyCode = 0;
        this.receiveObj.grNo = this.myObj.gr;

        this.discountToShow = res[0].timelyDiscountToShow;
        this.advanceToShow = res[0].advanceTimelyDiscount;
        this.dueDateToShow = res[0].dueDate;

        this.total = res[0].duesTotal;
        if (this.auto) {
          this.paid = res[0].duesTotal;
          this.balance = 0;
        } else {
          this.paid = null;
          this.balance = res[0].duesTotal;
        }
        var elem = this.$refs["paid"];
        elem.state = undefined;

        this.gridLoading = false;
        this.historyLoading = false;
      }
      this.checkLedger();
    },
    async loadByFamily() {
      this.fromChallan = false;
      this.challanObj.feesIDs = [];
      this.receiveObj.feesIDs = [];

      if (this.$refs.selectableTable) {
        this.$refs.selectableTable.clearSelected();
      }

      this.iFamily = true;
      this.allSelect = false;
      this.gridLoading = true;
      this.historyLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "Fees/GetStudentFeesDataExemption?db=" +
          this.$store.state.userData.db +
          "&campusID=" +
          this.$store.state.userData.cId +
          "&grNo=&familyCode=" +
          this.myObj.familyCode,
        token: this.$store.state.userData.token,
      };
      let res = await this.get(obj);
      if (this.receiving) {
        this.receiving = false;
        if (this.fromShortcut && this.slipShortcut) {
          this.printSlip(this.slipNo, res[0].duesTotal);
        } else if (this.settings.printSlip) {
          //Print Slip
          this.printSlip(this.slipNo, res[0].duesTotal);
        }
        this.fromShortcut = false;
        this.slipShortcut = false;
        this.check = 1;
      }
      // console.log("res", res);
      if (res === "No record found") {
        this.gridLoading = false;
        this.historyLoading = false;
        this.items = [];
        this.history = [];
        this.students = [];
        this.fromGr = true;

        this.myObj = {
          gr: null,
          familyCode: this.myObj.familyCode,
          challanNo: null,
          id: 0,
        };
        this.total = null;
        this.balance = null;
        this.paid = null;

        this.discountToShow = 0;
        this.advanceToShow = 0;
        this.dueDateToShow = null;

        this.receiveObj.feesIDs = [];
      } else {
        this.items = res[0].dues;
        this.history = res[0].history;

        this.students = res[0].std;
        this.myObj = this.students[0];
        // console.log(this.myObj);
        if (this.students.length > 1) this.fromGr = false;
        else this.fromGr = true;

        this.collection = {
          sID: this.myObj.id,
          year: new Date().getFullYear(),
          userID: this.$store.state.userData.userID,
          feesTypeIDs: [],
          months: [],
          onBehalf: 0,
        };
        this.receiveObj.feesIDs = [];
        this.receiveObj.grNo = "";
        this.receiveObj.familyCode = this.myObj.familyCode;

        this.discountToShow = res[0].timelyDiscountToShow;
        this.advanceToShow = res[0].advanceTimelyDiscount;
        this.dueDateToShow = res[0].dueDate;

        this.total = res[0].duesTotal;
        if (this.auto) {
          this.paid = res[0].duesTotal;
          this.balance = 0;
        } else {
          this.paid = null;
          this.balance = res[0].duesTotal;
        }
        var elem = this.$refs["paid"];
        elem.state = undefined;

        this.gridLoading = false;
        this.historyLoading = false;
      }
      this.checkLedger();
    },
    async loadHistoryGrid() {
      this.historyLoading = true;
      if (this.fromGr) {
        var obj = {
          url:
            this.$store.state.domain +
            "Fees/GetStudentFeesDataExemption?db=" +
            this.$store.state.userData.db +
            "&campusID=" +
            this.$store.state.userData.cId +
            "&grNo=" +
            this.myObj.gr +
            "&familyCode=0&sID=" +
            this.myObj.id,
          token: this.$store.state.userData.token,
        };
      } else {
        var obj = {
          url:
            this.$store.state.domain +
            "Fees/GetStudentFeesDataExemption?db=" +
            this.$store.state.userData.db +
            "&campusID=" +
            this.$store.state.userData.cId +
            "&grNo=&familyCode=" +
            this.myObj.familyCode,
          token: this.$store.state.userData.token,
        };
      }
      let res = await this.get(obj);
      // console.log("History -----------", res);
      if (res === "No record found") {
        this.historyLoading = false;
        this.items = [];
        this.history = [];
        this.students = [];
        this.myObj = {
          gr: this.myObj.gr,
          id: 0,
        };
        this.total = null;
        this.balance = null;
        this.paid = null;

        this.discountToShow = 0;
        this.advanceToShow = 0;
        this.dueDateToShow = null;

        this.receiveObj.feesIDs = [];
      } else {
        this.items = res[0].dues;
        this.history = res[0].history;
        // this.myObj = res[0].std[0];

        this.students = res[0].std;
        this.myObj = this.students[0];
        // console.log(this.myObj);
        if (this.students.length > 1) this.fromGr = false;
        else this.fromGr = true;

        this.collection = {
          sID: this.myObj.id,
          year: new Date().getFullYear(),
          userID: this.$store.state.userData.userID,
          feesTypeIDs: [],
          months: [],
          onBehalf: 0,
        };

        this.discountToShow = res[0].timelyDiscountToShow;
        this.advanceToShow = res[0].advanceTimelyDiscount;
        this.dueDateToShow = res[0].dueDate;

        this.total = res[0].duesTotal;
        this.paid = null;
        this.balance = res[0].duesTotal;
        this.historyLoading = false;
      }
    },
    async openSettings(open) {
      if (open) this.loadSetting = true;

      let myitem = await this.get({
        url:
          this.$store.state.domain +
          "FeeSettings/LoadData?campusID=" +
          this.$store.state.userData.cId +
          "&db=" +
          this.$store.state.userData.db,
        token: this.$store.state.userData.token,
      });
      if (myitem == null || myitem == "") {
        this.settings = {
          id: 0,
          campusID: this.$store.state.userData.cId,
          issueDate: null,
          dueDate: null,
          expiryDate: null,
          lateFee: 0,
          timelyDiscount: 0,
          printSlip: false,
          sendSMS: false,
          instructions: "",
          bankName: "",
          accountNumber: "",
          printBy: "Family",
          titleOfAccount: "",
        };
      } else {
        this.settings = myitem;
        this.lateFee = this.settings.lateFee;
        this.timelyDiscount = this.settings.timelyDiscount;
      }
      if (open) {
        this.showSettings = true;
        this.loadSetting = false;
      }
    },
    async saveSettings() {
      // console.log(this.settings);
      this.settings.lateFee = parseInt(this.settings.lateFee);
      this.settings.timelyDiscount = parseInt(this.settings.timelyDiscount);
      this.request = true;

      var status = await this.post({
        url:
          this.$store.state.domain +
          "FeeSettings/Save?db=" +
          this.$store.state.userData.db,
        body: this.settings,
        message: "Settings saved successfully.",
        context: this,
        token: this.$store.state.userData.token,
      });
      if (status) {
        this.showSettings = false;
        this.lateFee = this.settings.lateFee;
        this.timelyDiscount = this.settings.timelyDiscount;
      }
      this.request = false;
      if (this.noticeObj || this.cHeadObj) {
        let data = [];
        if (this.noticeObj) data.push(this.noticeObj);
        if (this.cHeadObj) data.push(this.cHeadObj);

        var sett = await this.post({
          url:
            this.$store.state.domain +
            "Settings/SaveNew?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          body: data,
          importing: true,
          context: this,
          token: this.$store.state.userData.token,
        });
        if (sett) {
          this.loadSubSetting();
        }
      }
    },
    showHistory() {
      // this.$root.$emit("bv::toggle::collapse", "collapse-1");
      this.$store.commit("setHistory", true);
      // console.log(this.$store.state.history);
    },
    hideHistory() {
      this.$store.commit("setHistory", false);
    },
    showForm() {
      if (this.myObj.gr == null || this.myObj.gr == "" || this.myObj.id == 0) {
        this.$bvToast.toast("Please select the student first.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        this.createForm = true;
      }
    },
    closeForm() {
      this.createForm = false;

      // clearing selected ids when form closed
      this.challanObj.feesIDs = [];
      this.receiveObj.feesIDs = [];
      this.paid = null;
      this.getBalance();
    },
    variantFeetype(id) {
      return this.collection.feesTypeIDs.includes(id);
    },
    selectFeetype(id, recurr) {
      if (this.collection.feesTypeIDs.length == 0) {
        this.collection.feesTypeIDs.push(id);
      } else {
        let data = this.collection.feesTypeIDs.filter((el) => {
          return el !== id;
        });
        // console.log("data", data);
        if (data.length == this.collection.feesTypeIDs.length) {
          this.collection.feesTypeIDs.push(id);
        } else {
          this.collection.feesTypeIDs = data;
        }
      }
      if (recurr == "after due date") {
        if (this.variantFeetype(id) == true) {
          this.showOnBehalf = true;
        } else {
          this.showOnBehalf = false;
          this.collection.onBehalf = 0;
        }
      }
      // console.log(this.collection.feesTypeIDs);
    },
    selectOnBehalf(id) {
      if (this.collection.onBehalf == id) this.collection.onBehalf = 0;
      else this.collection.onBehalf = id;
    },
    variantMonth(name) {
      return this.collection.months.includes(name);
    },
    selectMonth(name) {
      if (this.collection.months.length == 0) {
        this.collection.months.push(name);
      } else {
        let data = this.collection.months.filter((el) => {
          return el !== name;
        });
        // console.log("data", data);
        if (data.length == this.collection.months.length) {
          this.collection.months.push(name);
        } else {
          this.collection.months = data;
        }
      }
      // console.log(this.collection.months);
    },
    async saveFee() {
      this.CheckType();
      this.CheckMonths();
      this.CheckYear();
      if (
        this.CheckType() == false ||
        this.CheckMonths() == false ||
        this.CheckYear() == false
      ) {
        return this.$bvToast.toast("Please fill the form correctly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        // console.log(this.collection);
        this.saving = true;
        var status = await this.post({
          url:
            this.$store.state.domain +
            "Fees/CreateFee?db=" +
            this.$store.state.userData.db,
          body: this.collection,
          message: "fee created successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) {
          if (this.fromGr) this.loadGrid();
          else this.loadByFamily();
        }
        this.saving = false;
      }
    },
    async LoadData() {
      this.dataLoading = true;

      var obj = {
        url:
          this.$store.state.domain +
          "FeeType?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.allfeeType = await this.get(obj);
      // console.log("fee data", this.allfeeType);

      this.dataLoading = false;
    },

    async loadAccounts() {
      var obj2 = {
        url:
          this.$store.state.domain +
          "CollectionAccounts?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      let result = await this.get(obj2);
      // console.log("acc", result);
      if (result !== "NotFound") this.accTypes = result;
      else this.accTypes = [];

      if (
        this.reportDomain === "myskoolhpgs" &&
        this.$store.state.loggedUser.campusID != 1
      ) {
        this.accTypes = this.accTypes.filter((el) => [18, 20].includes(el.id));
      }
      // if exists
      if (this.accTypes.find((el) => el.id == this.$store.state.accountType)) {
        this.accountType = this.$store.state.accountType;
      }
      // not exists then first record
      else if (this.accTypes.length > 0) {
        this.accountType = this.accTypes[0].id;
        this.$store.commit("setAccountType", this.accountType);
      }
      // no records of accounts then set to 0
      else {
        this.$store.commit("setAccountType", 0);
        this.accountType = this.$store.state.accountType;
      }
      this.challanObj.accountID = this.$store.state.accountType;
      this.receiveObj.accountID = this.$store.state.accountType;
    },
    openAccount() {
      this.accIcon = "PlusIcon";
      this.accountbar = true;
      this.accountObj = {
        id: 0,
        campusID: this.$store.state.userData.cId,
        account: "",
        status: "active",
        accountID: 0,
      };
      var elem = this.$refs["accname"];
      elem.state = undefined;
    },
    checkAccount() {
      var elem = this.$refs["accname"];
      if (this.accountObj.account == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async saveAcc() {
      if (this.checkAccount() == true) {
        this.savingacc = true;
        if (this.accountObj.id == 0) {
          var status = await this.post({
            url:
              this.$store.state.domain +
              "CollectionAccounts?db=" +
              this.$store.state.userData.db,
            body: this.accountObj,
            message: "Account added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
        } else {
          var status = await this.put({
            url:
              this.$store.state.domain +
              "CollectionAccounts/" +
              this.accountObj.id +
              "?db=" +
              this.$store.state.userData.db,

            message: "Account updated successfully.",
            context: this,
            body: this.accountObj,
            token: this.$store.state.userData.token,
          });
        }
        if (status) {
          this.loadAccounts();
          this.accountObj = {
            id: 0,
            campusID: this.$store.state.userData.cId,
            account: "",
            status: "active",
            accountID: 0,
          };
          var elem = this.$refs["accname"];
          elem.state = undefined;
        }
        this.savingacc = false;
      }
    },
    async editAcc(acc) {
      this.accountObj = { ...acc };
      this.accIcon = "Edit2Icon";
      var elem = this.$refs["accname"];
      elem.state = undefined;
      // console.log(this.accountObj);
    },

    async deleteAcc(acc) {
      // console.log(acc);
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "CollectionAccounts/" +
            acc.id +
            "?db=" +
            this.$store.state.userData.db,

          message: "Account deleted successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.loadAccounts();
      }
    },

    async onRowSelected(row) {
      // console.log(row);
      this.myObj.gr = row[0].grNo;
      this.myObj.familyCode = row[0].family_code;
      // console.log(this.myObj);
      this.loadGrid(row[0].id);
    },
    onChallanRowSelect(ch) {
      // console.log(ch);
      this.paid = ch.reduce((total, c) => total + c.fees.balance, 0);
      // console.log(this.paid);
      if (this.fromChallan) {
        this.challanObj.feesIDs = ch.reduce((acc, c) => {
          acc.push(c.fees.id);
          return acc;
        }, []);
        // console.log("ch", this.challanObj.feesIDs);
      } else {
        this.receiveObj.feesIDs = ch.reduce((acc, c) => {
          acc.push(c.fees.id);
          return acc;
        }, []);
        // console.log("rec", this.receiveObj.feesIDs);
      }

      this.getBalance();
    },
    checkSelect() {
      // console.log(this.allSelect);
      this.allSelect ? this.selectAllRows() : this.clearSelected();
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    getBalance() {
      var elem = this.$refs["paid"];
      if (this.paid > this.total || this.paid < 0) {
        elem.state = false;
      } else {
        this.balance = this.total - this.paid;
        elem.state = undefined;
      }
    },
    checkRecAmount() {
      var elem = this.$refs["paid"];
      if (this.paid <= 0 || this.paid == null || this.paid > this.total) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkRecAccount() {
      var elem = this.$refs["acc"];
      if (this.fromChallan) {
        if (this.challanObj.accountID == 0 || this.challanObj.accountID == "") {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else {
        if (this.receiveObj.accountID == 0 || this.receiveObj.accountID == "") {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      }
    },
    checkDate() {
      // console.log(this.receiveObj.rcvDate);
      this.challanObj.rcvDate = this.receiveObj.rcvDate;
      this.$store.commit("setDate", this.receiveObj.rcvDate);
      var elem = this.$refs["rcvDate"];
      if (this.receiveObj.rcvDate == "" || this.receiveObj.rcvDate == null) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    setAccount() {
      this.$store.commit("setAccountType", this.accountType);
      this.challanObj.accountID = this.$store.state.accountType;
      this.receiveObj.accountID = this.$store.state.accountType;

      // console.log(this.$store.state.accountType);
    },
    async receiveFee(text) {
      if (
        this.fromShortcut &&
        (this.paid == 0 || this.paid == null || this.paid == "")
      )
        this.paid = this.total;
      if (
        this.checkRecAmount() == false ||
        this.checkRecAccount() == false ||
        this.checkDate() == false
      ) {
        this.$bvToast.toast("Please enter the required details.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
        // this.paid = null;
        this.fromShortcut = false;
        this.slipShortcut = false;
        this.check = 1;
      } else {
        if (this.fromChallan) text = "challan";
        if (text == "challan") {
          this.challanObj.amount = parseInt(this.paid);
          this.challanObj.challanNo = parseInt(this.myObj.challanNo);
          // this.challanObj.accountID = this.$store.state.accountType;

          // console.log(this.challanObj);

          this.receiving = true;
          var status = await this.post({
            url:
              this.$store.state.domain +
              "Fees/ReceiveFeesByChallan?db=" +
              this.$store.state.userData.db +
              "&campusID=" +
              this.$store.state.userData.cId,
            body: this.challanObj,
            message: "Fee received successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.receiving = false;
          if (status) {
            this.gridLoading = true;
            this.items = [];
            this.challanObj = {
              challanNo: 0,
              amount: 0,
              rcvDate: this.$store.state.date,
              userID: this.$store.state.userData.userID,
              campusID: this.$store.state.userData.cId,
              feesIDs: [],
              accountID: this.$store.state.accountType,
            };
            this.myObj = {
              grNo: null,
              familyCode: null,
              challanNo: null,
              id: 0,
            };
            this.total = null;
            this.balance = null;
            this.paid = null;

            this.gridLoading = false;
          }
        } else {
          this.receiveObj.amount = parseInt(this.paid);
          // this.receiveObj.accountID = this.$store.state.accountType;
          // this.receiveObj.grNo = this.myObj.gr;

          // console.log(this.receiveObj);
          this.receiving = true;
          if (this.chDetails && this.chDetails.valueBool) {
            this.receiveObj.feesChequeDetails = {
              id: 0,
              billID: 0,
              organizationID: this.otherObj.organization,
              bank: this.otherObj.bank,
              cheque: this.otherObj.cheque,
              otherDetails: "",
              campusID: this.$store.state.userData.cId,
            };
          } else this.receiveObj.feesChequeDetails = null;

          // "Fees/ReceiveFees?db=" +
          var status = await this.post({
            url:
              this.$store.state.domain +
              "Fees/ReceiveFeesNew?db=" +
              this.$store.state.userData.db +
              "&withLate=" +
              this.withLate,
            body: this.receiveObj,
            message: "Fee received successfully.",
            context: this,
            token: this.$store.state.userData.token,
            feeReceive: true,
          });
          // console.log(status);
          if (status) {
            this.slipNo = status;
            if (this.receiveObj.grNo == "") this.loadByFamily();
            else this.loadGrid();
          }
        }
      }
    },
    CheckMobile() {
      // var re = /^(\+92|0|92)[0-9]{10}$/;
      // if (re.test(this.myObj.contact) && this.myObj.contact !== "") {
      //   return (this.contacterror = false);
      // } else {
      //   return (this.contacterror = true);
      // }
      // console.log(this.myObj.contact.length);
      if (this.myObj.contact.length == 12) {
        return (this.contacterror = false);
      } else {
        return (this.contacterror = true);
      }
    },
    CheckEmail() {
      var elem = this.$refs["email"];
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (
        re.test(String(this.myObj.email).toLowerCase()) &&
        this.myObj.email !== ""
      ) {
        return (elem.state = true);
      } else {
        return (elem.state = false);
      }
    },
    CheckType() {
      var elem = this.$refs["type"];
      if (this.collection.feesTypeIDs.length == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckMonths() {
      var elem = this.$refs["months"];
      if (this.collection.months.length == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckYear() {
      var elem = this.$refs["year"];
      if (this.collection.year == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckTag() {
      var elem = this.$refs["tag"];
      if (this.myObj.tag == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },

    Form() {
      this.$router.push("/apps/schoolinfo");
    },
    checkTitle() {
      var elem = this.$refs["title"];
      return (elem.state = this.myObj.name.length > 2 ? true : false);
    },

    checkStatus() {
      if (this.myObj.Status == "") {
        return (this.errors.status = true);
      } else {
        return (this.errors.status = false);
      }
    },
    OpenModal() {
      // this.$bvModal.show("modal-upload");
      this.showUpload = true;
      this.uploadObj = {
        accountID: this.accountType,
        isTransport: true,
      };
    },
    showDeleted() {
      this.$bvModal.show("modal-receipt");
    },
    openSlipModal() {
      this.$bvModal.show("modal-slip");
    },
    checkSlipFrom() {
      var elem = this.$refs["slip_from"];
      if (this.slipObj.from == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkSlipTill() {
      var elem = this.$refs["slip_till"];
      if (this.slipObj.till == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkRange() {
      return parseInt(this.slipObj.from) <= parseInt(this.slipObj.till);
    },
    printBulk() {
      this.checkSlipFrom();
      this.checkSlipTill();
      if (this.checkSlipFrom() == true && this.checkSlipTill() == true) {
        if (this.checkRange() == true) {
          this.printingSlip = true;
          let url =
            `https://${this.reportDomain}.myskool.app/Fee/SlipRange?dbb=` +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&from=" +
            parseInt(this.slipObj.from) +
            "&till=" +
            parseInt(this.slipObj.till);

          // console.log(url);
          this.printingSlip = false;
          window.open(url, "_blank");
        } else {
          this.$bvToast.toast("Please enter correct range", {
            title: "Error!",
            variant: "danger",
            toaster: "b-toaster-top-center",
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.profileImg {
  width: 70px;
  height: 70px;
  object-fit: cover;
  margin: 0px 10px;
  transition: 1s ease-in-out;
}
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.rightCard {
  border: 1px solid lightgray;
  /* box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1); */
}
.createCard {
  border: 1px solid #333;
  /* box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1); */
}
.setting {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid gray;
  cursor: pointer;
}
@media only screen and (max-width: 496px) {
  /* your CSS here */
  .topButtons {
    margin-top: 10px;
  }
}
.my-btn {
  color: #605757;
}
.swiper-button-next {
  right: 0px;
}
.swiper-button-prev {
  left: 0px;
}
.my-collapsing {
  height: 55px;
  overflow: hidden;
}
.my-buttons {
  position: absolute;
  top: 0px;
  right: 10px;
  cursor: pointer;
}
.v-select.set_multi::v-deep .vs__dropdown-toggle {
  max-height: 300px !important;
  overflow-y: auto;
}
</style>
